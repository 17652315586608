import {
  Holding_SummariseForConsumer,
} from '@rabbit/bizproc/client';
import {
  DTHoldingProxy,
  FBD_Consumer_Private,
  FBD_HoldingProxy,
} from '@rabbit/data/types';

export function useGetConsumerHoldings(consumerId: string) {
  /** Runs the holding summariser (more detailed, more computationally intensive) for a single holding */
  // TODO: @DEV-754: This should all be handled by Holding_Proxy.
  const getSingleHolding = async (holdingId: string) => {
    // if (!consumerId) return new Error('A valid consumer persona is required');

    const summary = await Holding_SummariseForConsumer(holdingId);
    return summary || [];
  };

  const getSingleHoldingProxy = async (holdingId: string) => {
    const holdingProxy = await FBD_HoldingProxy.get(holdingId);
    return holdingProxy;
  };

  const getAllHoldingProxies = async (): Promise<DTHoldingProxy[] | Error> => {
    if (!consumerId) return new Error('A valid consumer persona is required');

    const consumerPersona = await FBD_Consumer_Private.get(consumerId);
    if (!consumerPersona)
      return new Error('A valid consumer persona is required');

    const proxyQuery = FBD_HoldingProxy.query().where(
      'consumer',
      '==',
      consumerPersona.docid
    );
    const holdingProxies = await proxyQuery.getContents();
    return holdingProxies;
  };

  return {
    getSingleHolding,
    getSingleHoldingProxy,
    getAllHoldingProxies,
  };
}

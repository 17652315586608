// CaseFlowCase
// I decided to switch to a class based approach to manage application interactions with an individual case.
// This wraps everything up in one spot and we can create a very controlled environment. My previous design was
// more spurious with functions that you call just by knowing that you have to call them, and the onus was on you
// to make sure that everything was in the right state for you to call the function.

import { CaseFlowValidator } from './validation';
import {
  CaseFlowConfiguration,
  DTCaseFlowActorPrivate,
  DTCaseFlowCase,
  FBD_CaseFlowActorPrivate,
  FBD_CaseFlowCase,
  GEChat,
  GEK_CaseSpotlightChange,
  GEK_Chat,
  GEK_FactUpdate,
  GEK_LinkCase,
  GEK_StateChange,
  Gazette,
  ActorContext,
  PossibleActions,
  CFAction,
  PersonaTypeSingleLetter,
  CaseFlowFacts,
  SpotlightAlteration,
} from '@rabbit/data/types';
import {
  CaseFlow_GetConfigurationForCaseType,
  NoConfiguration,
} from './configuration/configuration';
import { IsValidLinkToDocument } from '../helpers';

// The new design is a case that you can view the state of at any time. It may or may not be stored in firebase,
// and it may have been altered by the interface in memory. However the interface can query it to get the assumed state
// so that you can display the interface correctly before committing to the database.

// The class design also helps when we have to hook up to react. Because everything is encapsulated, we can trigger
// subscription events when things change.

export type ActorCaseCollection = {
  [key: string]: DTCaseFlowActorPrivate;
};

type CaseLinkCollection = {
  master: string[];
  sub: string[];
};

/** A base class for a CaseFlow case that you are working on.
 *
 * Think of this class as "Read Only". Do not put any alteration functions in here.
 *
 * Alterations are to be performed in the derived classes of ClientCaseFlowCase and ServerCaseFlowCase.
 *
 * Internally, it has a cache that can receive writes from those derived classes, so that it can provide up to date information.
 * So maybe it should be classed as "writable but only by a derived class"
 */

let TemporaryCaseNumber = 1;
const TCNPrefix = new Date().getTime();

export class BaseCaseFlowCase {
  protected _case: DTCaseFlowCase;
  protected _actorCases: ActorCaseCollection = {};
  protected merged_gazette: Gazette = [];
  configuration: CaseFlowConfiguration = NoConfiguration;

  /** The principal of the case - the owner of the case as far as role playing is concerned - "the company" */
  protected principalPersona: string | null;

  /** The persona who is viewing/editing the case, or in uber mode, the persona who the case is being altered on behalf of.
   * The persona here may be a delegate of the principal, or it may be the principal itself.
   */
  protected operatingPersona: string | null;

  /** True if we are UberUser (and can see all private files) */
  protected uber = false;

  protected currentCaseState = '_birth';
  protected _case_id;

  protected actorContext: ActorContext | null = null;

  protected writes: Gazette = [];

  constructor(
    operatingPersona: string,
    principalPersona: string,
    case_id?: string
  ) {
    // TODO: This is very much a hack.
    this.operatingPersona = operatingPersona;
    this.principalPersona = principalPersona;

    if (case_id) {
      this._case_id = case_id;
    } else {
      // We are making a new case, so prepend with $ to indicate that it is not yet saved
      this._case_id = `$${TCNPrefix}${TemporaryCaseNumber++}`;
    }
    this._case = FBD_CaseFlowCase.empty();
    this._case.casetype = 'unspecified';
  }

  async RefreshConfiguration() {
    this.configuration = await CaseFlow_GetConfigurationForCaseType(
      this._case.casetype
    );
  }

  GetCaseType() {
    return this._case.casetype;
  }

  async SetCaseType(newType: string) {
    if (this._case.casetype !== 'unspecified')
      throw new Error('Case type can only be set once.');
    this._case.casetype = newType;
    await this.RefreshConfiguration();
  }

  async LoadCase(case_docid: string) {
    this._case_id = case_docid;
    // Load the case first
    const case_doc = await FBD_CaseFlowCase.get(case_docid);
    if (!case_doc)
      throw new Error('CaseFlowCase.LoadCase: Case does not exist.');
    this._case = case_doc;

    // find out which role we are playing
    if (this.principalPersona) {
      for (const role in this._case.actors) {
        if (this.principalPersona === this._case.actors[role]) {
          // found!
          this.actorContext = {
            role,
            persona: this.principalPersona,
          };
        }
      }

      if (this.actorContext === null) {
        throw new Error(
          'Could not figure out which actor this persona is (is the principal set properly? principal=' +
            this.principalPersona +
            ')'
        );
      }
    }

    if (this.uber) {
      // Load everything
      const all_actors = this._case.actors;
      for (const role in all_actors) {
        const persona_id = all_actors[role];
        if (IsValidLinkToDocument(persona_id)) {
          const actor_doc = await FBD_CaseFlowActorPrivate.get(
            `${persona_id}_${case_docid}`
          );
          if (actor_doc) {
            this._actorCases[role] = actor_doc;
          }
        }
      }
    } else {
      // Load the actor data
      if (!this.actorContext) {
        throw new Error(
          "Cannot load case, there is no actorContext so I don't know who you are."
        );
      }

      const actor_doc = await FBD_CaseFlowActorPrivate.get(
        `${this.actorContext.persona}_${case_docid}`
      );
      if (actor_doc) {
        this._actorCases[this.actorContext.role] = actor_doc;
      }
    }

    await this.RespondToUpdatedDocuments();
  }

  protected async RespondToUpdatedDocuments() {
    // TODO: Here is where we figure out the configuration based on the case status,
    // but for now we are hardcoding it to shelta
    await this.RefreshConfiguration();

    this.MakeMergedGazette();

    // State - we start in the state identified in the document, but if the user has forthcoming actions that alter the state, then these need to be applied.
    this.currentCaseState = this._case.state;
    this.writes.forEach((entry) => {
      if (entry.k === GEK_StateChange) {
        if (entry.who === 'CASE') {
          this.currentCaseState = entry.newState;
        }
      }
    });
  }

  GetCaseId() {
    if (this._case_id.charAt(0) === '$')
      throw new Error("Can't get case ID of new case");
    return this._case_id;
  }

  GetTemporaryCaseId() {
    if (this._case_id.charAt(0) !== '$')
      throw new Error("This case is not new and doesn't have temporary ID");
    return this._case_id;
  }

  protected GetUnfetteredCaseId() {
    return this._case_id;
  }

  GetCaseState() {
    return this.currentCaseState;
  }

  GetCaseStationConfig() {
    return this.configuration.stations[this.currentCaseState];
  }

  GetActors() {
    return this._case.actors;
  }

  GetActorContext() {
    return this.actorContext;
  }

  GetTimeCreated() {
    return this._case.tcreate;
  }

  GetTimeUpdated() {
    return this._case.tupdate;
  }

  GetCaseSpotlight(): string[] {
    // Get last spotlight alteration entry from merged_gazette
    for (let n = this.merged_gazette.length - 1; n >= 0; n--) {
      const entry = this.merged_gazette[n];
      if (entry.k === GEK_CaseSpotlightChange) {
        return entry.spotlight;
      }
    }
    return [];
  }

  GetLinkedCases(): CaseLinkCollection {
    const result: CaseLinkCollection = {
      master: [],
      sub: [],
    };

    for (const gazette_item of this.merged_gazette) {
      if (gazette_item.k === GEK_LinkCase) {
        // I guess you could do this in a more generic way one day.
        if (gazette_item.is_my === 'master') {
          result.master.push(gazette_item.case);
        }
        if (gazette_item.is_my === 'sub') {
          result.sub.push(gazette_item.case);
        }
      }
    }

    return result;
  }

  /** Return an object with the possible actions, with each entry listing any requirements and other details.
   * It is called "Possible" actions because you may need to fulfil some requirements before you can trigger them.
   */
  GetPossibleActions() {
    const actions: PossibleActions = {};
    const stationConfig = this.configuration.stations[this.currentCaseState];
    if (!this.actorContext) {
      throw new Error(
        'GetPossibleActions: Need to access the case as a particular actor'
      );
    }

    if (!stationConfig?.actions) return [];
    for (const key in stationConfig.actions) {
      const action = stationConfig.actions[key];
      if (action.available_to.includes(this.actorContext.role)) {
        actions[key] = {
          key,
          label: action.label || key,
          requirements: [],
          params: action.params || {},
          available_to: action.available_to,
        };
      }
    }

    // Add in the global actions
    const globalActions = this.configuration.global_actions;
    if (globalActions) {
      for (const key in globalActions) {
        const action = globalActions[key];
        if (action.available_to.includes(this.actorContext.role)) {
          actions[key] = {
            key,
            label: action.label || key,
            requirements: [],
            params: action.params || {},
            available_to: action.available_to,
          };
        }
      }
    }
    return actions;
  }

  /** Return an object with the possible actions in a given station, excluding global actions, with each entry listing any requirements and other details.*/
  GetPossibleActionsInStation() {
    const actions: PossibleActions = {};
    const stationConfig = this.configuration.stations[this.currentCaseState];

    if (!this.actorContext) {
      throw new Error(
        'GetPossibleActions: Need to access the case as a particular actor'
      );
    }

    if (!stationConfig?.actions) return [];
    for (const key in stationConfig.actions) {
      const action = stationConfig.actions[key];
      if (action.available_to.includes(this.actorContext.role)) {
        actions[key] = {
          key,
          label: action.label || key,
          requirements: [],
          params: action.params || {},
          available_to: action.available_to,
        };
      }
    }
    return actions;
  }

  /** Return an object with all the global actions available, with each entry listing any requirements and other details. */
  GetGlobalActions() {
    const actions: PossibleActions = {};
    const globalActions = this.configuration.global_actions;

    if (!this.actorContext) {
      throw new Error(
        'GetPossibleActions: Need to access the case as a particular actor'
      );
    }

    if (globalActions) {
      for (const key in globalActions) {
        const action = globalActions[key];
        if (action.available_to.includes(this.actorContext.role)) {
          actions[key] = {
            key,
            label: action.label || key,
            requirements: [],
            params: action.params || {},
            available_to: action.available_to,
          };
        }
      }
    }
    return actions;
  }

  GetOperatorAndPrincipal() {
    return {
      operator: this.operatingPersona,
      principal: this.principalPersona,
    };
  }

  MakeMergedGazette() {
    // Merge the gazette
    this.merged_gazette = [...this._case.gazette, ...this.writes];
    for (const key in this._actorCases) {
      const actor = this._actorCases[key];
      if (actor) {
        this.merged_gazette = [...this.merged_gazette, ...actor.gazette];
      }
    }
    // Sort the gazette based on time ascending
    this.merged_gazette.sort((a, b) => a.t - b.t);
  }

  GetMergedGazette() {
    return this.merged_gazette;
  }

  /** Gets an array of the stations the case has been through, in order, since creation */
  GetStateHistory() {
    const stationHistory: string[] = [];
    for (const gazette_item of this.merged_gazette) {
      if (gazette_item.k === GEK_StateChange) {
        stationHistory.push(gazette_item.newState);
      }
    }
    return stationHistory;
  }

  GetAllStates() {
    const stateHistory: any[] = [];
    for (const gazette of this.merged_gazette) {
      if (gazette.k === GEK_StateChange) {
        stateHistory.push(gazette);
      }
    }
    return stateHistory;
  }
  /* -------------------------------------------------------------------------- */
  /*                                    Facts                                   */
  /* -------------------------------------------------------------------------- */

  GetAllFacts() {
    // It's simply a case of running through the case gazette and merging facts as you go along
    const facts: { [key: string]: any } = {};
    for (const gazette_item of this.merged_gazette) {
      if (gazette_item.k === GEK_FactUpdate) {
        Object.assign(facts, gazette_item.kv);
      }
    }
    return facts;
  }

  GetFactHistory(fact_name: string): CaseFlowFactHistoryEntry[] {
    const history: CaseFlowFactHistoryEntry[] = [];
    for (const gazette_item of this.merged_gazette) {
      if (gazette_item.k === GEK_FactUpdate) {
        if (fact_name in gazette_item.kv) {
          history.push({
            time: gazette_item.t,
            actor: gazette_item.a,
            value: gazette_item.kv[fact_name],
          });
        }
      }
    }
    return history;
  }

  /* -------------------------------------------------------------------------- */
  /*                                    Chat                                    */
  /* -------------------------------------------------------------------------- */
  GetAllChat(): GEChat[] {
    return this.merged_gazette.filter(
      (item) => item.k === GEK_Chat
    ) as GEChat[];
  }

  GetLastChatMessage(): GEChat | undefined {
    const chat = this.GetAllChat();
    if (chat.length > 0) return chat[chat.length - 1];
    return undefined;
  }

  GetActorChatLastSeen(actor: string): number {
    return this._actorCases[actor]?.chatLastSeen ?? 0;
  }

  GetMyChatLastSeen(): number {
    if (!this.actorContext)
      throw new Error(
        'GetMyChatLastSeen: Not accessing case as any particular actor'
      );
    return this.GetActorChatLastSeen(this.actorContext.role);
  }
}

export type CaseFlowFactHistoryEntry = {
  time: number;
  actor: string;
  value: any;
};

export type CaseAlteringFunctionality = {
  Alter_Actor: (
    actorType: PersonaTypeSingleLetter,
    actorPersonaID: string
  ) => void;
  Alter_Facts: (facts: CaseFlowFacts) => void;
};

export abstract class BaseCaseFlowCaseWithAlteration
  extends BaseCaseFlowCase
  implements CaseAlteringFunctionality
{
  abstract Alter_Actor(role: string, actorPersonaID: string): void;
  abstract Alter_Facts(facts: CaseFlowFacts): void;
  abstract Alter_CaseSpotlight(alteration: SpotlightAlteration): void;
}

export class ActionRuntime {
  action: CFAction;

  cfc: BaseCaseFlowCaseWithAlteration;

  params: { [key: string]: string } = {};

  constructor(action: CFAction, cfc: BaseCaseFlowCaseWithAlteration) {
    this.action = action;
    this.cfc = cfc;
  }

  /** Scans the input string for params of the form [[PARAM]] and fills them from params variable. Throws exception if param not found. */
  FillParamsInString(input: string) {
    return input.replace(/\[\[(.*?)\]\]/g, (match, param) => {
      const value = this.params[param];
      if (!value) {
        throw new Error(`Param [[${param}]] is not defined.`);
      }
      return value;
    });
  }

  /** Scans the input string for params of the form [[PARAM]]. Registers a violation in the validator if not found. */
  ValidatePotentialParams(
    input: string,
    location: string,
    validator: CaseFlowValidator
  ) {
    input.replace(/\[\[(.*?)\]\]/g, (match, param) => {
      const params = this.action.params;

      const value = params ? params[param] : undefined;
      if (!value) {
        validator.AddIssue(location, `Param [[${param}]] is not defined.`);
      }
      return '';
    });
  }

  CheckAndFillParams(params: { [key: string]: any }) {
    const actparams = this.action.params || {};

    Object.keys(params).forEach((key) => {
      // Check it is valid
      if (!actparams[key]) {
        throw new Error(`Param "${key}" is not defined.`);
      }
      // TODO: Here you would validate the type of the param
      this.params[key] = params[key];
    });

    // Make sure all required params have been provided
    Object.keys(actparams).forEach((key) => {
      const getFrom = actparams[key].get_from;
      if (getFrom) {
        // Load it from the places we are supposed to get it from
        const places = Array.isArray(getFrom) ? getFrom : [getFrom];
        for (let n = 0; n < places.length; n++) {
          const split = places[n].split(':');
          if (split.length !== 2) {
            throw new Error(`Invalid get_from value "${places[n]}".`);
          }
          switch (split[0]) {
            case 'fact':
              {
                const fact = this.cfc.GetAllFacts();
                if (fact[split[1]]) {
                  this.params[key] = fact[split[1]];
                  // We are done so must break the loop
                  n = places.length;
                }
              }
              break;
            case 'actor':
              {
                const actor = (this.cfc.GetActors() as any)[split[1]];
                if (!actor) {
                  throw new Error(`Actor "${split[1]}" not found.`);
                }
                this.params[key] = actor;
                // We are done so must break the loop
                n = places.length;
              }
              break;
            default:
              throw new Error(`Invalid get_from value "${places[n]}".`);
          }
        }
        if (!this.params[key]) {
          throw new Error(
            `Param "${key}" could not be attained through get_from values provided.`
          );
        }
      }

      if (actparams[key].required && !this.params[key]) {
        throw new Error(`Param "${key}" is required but not provided.`);
      }
    });
  }
}

import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  setPersistence,
  updatePassword,
  updateProfile,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import { firebaseAuth } from '@rabbit/firebase/react';
import { useAuthIdToken, useAuthUser } from '@react-query-firebase/auth';
import { useNavigate } from 'react-router-dom';
import { PersonaLink, isValidPersonaId } from '@rabbit/data/types';

//@ts-ignore
const FIREBASE_MODE = import.meta.env.VITE_FIREBASE_MODE;

const GoogleSignInProvider = new GoogleAuthProvider();
const AppleSignInProvider = new OAuthProvider('apple.com');
AppleSignInProvider.addScope('email');
AppleSignInProvider.addScope('name');

// Always ask user to choose account
GoogleSignInProvider.setCustomParameters({
  prompt: 'select_account',
});

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: 'https://www.example.com/finishSignUp?cartId=1234',

  url: window.location.origin,
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios',
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12',
  // },
  // dynamicLinkDomain: window.location.hostname,
};

export function useFirebaseAuthentication() {
  const user = useAuthUser(['user'], firebaseAuth);
  const tokenResult = useAuthIdToken(['token'], firebaseAuth);
  const navigate = useNavigate();

  async function signInWithGoogle() {
    const NLIC_Federated = window.localStorage.getItem('NLIC_Federated');

    return signInWithPopup(firebaseAuth, GoogleSignInProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);

        // const token = credential.accessToken;
        // // The signed-in user info.
        const user = result.user;

        if (
          user.email &&
          FIREBASE_MODE &&
          !checkIfAllowedEmailForUser(user.email, FIREBASE_MODE)
        )
          await firebaseAuth.signOut();

        if (NLIC_Federated) {
          window.localStorage.removeItem('NLIC_Federated');
          window.localStorage.setItem('NLIC_User', JSON.stringify(user));
          navigate('/continue-claim');
          window.location.reload();
        }
        return user;
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log('error', error);
        throw new Error(error);
      });
  }

  async function signInWithApple() {
    return signInWithPopup(firebaseAuth, AppleSignInProvider)
      .then(async (result) => {
        const NLIC_Federated = window.localStorage.getItem('NLIC_Federated');
        // The signed-in user info.
        const user = result.user;

        if (
          user.email &&
          FIREBASE_MODE &&
          !checkIfAllowedEmailForUser(user.email, FIREBASE_MODE)
        )
          await firebaseAuth.signOut();

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        if (credential) {
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          console.log('SIGNED IN WITH APPLE', accessToken, idToken);
        }

        if (NLIC_Federated) {
          window.localStorage.removeItem('NLIC_Federated');
          window.localStorage.setItem('NLIC_User', JSON.stringify(user));
          navigate('/continue-claim');
          window.location.reload();
        }

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        console.log('error', error);
        throw new Error(error);
        // ...
      });
  }

  async function signInWithEmail(user: string, pass: string) {
    const res = await signInWithEmailAndPassword(firebaseAuth, user, pass);

    return res;
  }

  async function createWithEmail(user: string, pass: string) {
    const res = await createUserWithEmailAndPassword(firebaseAuth, user, pass);
    return res;
  }

  async function getSignInMethodsForEmail(email: string) {
    const methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
    return methods;
  }

  // NOTE: Deprecated, a custom email template is now used instead
  async function passwordlessSignIn(user: string) {
    await sendSignInLinkToEmail(firebaseAuth, user, actionCodeSettings);
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', user);
    // ...
  }

  function signOut() {
    // TODO: Kill all snapshot listeners before signOut!
    // Ideally we have all FE listeners in one place, so if anyone
    // would like to add any new listener, they can see what already exists
    firebaseAuth.signOut().then(
      function () {
        // Sign-out successful.
        console.log('Sign out successful');
      },
      function (error) {
        // An error happened.
      }
    );
  }

  async function setAuthPersistence(persistenceVal: string) {
    if (persistenceVal === 'local')
      await setPersistence(firebaseAuth, browserLocalPersistence);
    if (persistenceVal === 'session')
      await setPersistence(firebaseAuth, browserSessionPersistence);
    if (persistenceVal === 'none')
      await setPersistence(firebaseAuth, inMemoryPersistence);
  }

  // Untested yet - DC

  // async function resetPassword(email: string) {
  //   try {
  //     await sendPasswordResetEmail(firebaseAuth, email);
  //     console.log('Password reset email sent');
  //   } catch (e) {
  //     throw new Error(`Error sending password reset email: ${e}`);
  //   }
  // }

  // async function sendVerificationEmail() {
  //   try {
  //     if (!user) {
  //       throw new Error('No user signed in');
  //     }
  //     await sendEmailVerification(user);
  //     console.log('Verification email sent');
  //   } catch (e) {
  //     throw new Error(`Error sending verification email: ${e}`);
  //   }
  // }

  // async function updateUserEmail(email: string) {
  //   try {
  //     if (!firebaseAuth.currentUser) {
  //       throw new Error('No user signed in');
  //     }
  //     await updateEmail(firebaseAuth.currentUser, email);
  //     console.log('Email updated');
  //   } catch (e) {
  //     throw new Error(`Error updating email: ${e}`);
  //   }
  // }

  async function updateUserPassword(password: string) {
    try {
      if (!firebaseAuth.currentUser) {
        throw new Error('No user signed in');
      }
      await updatePassword(firebaseAuth.currentUser, password);
      console.log('Password updated');
    } catch (e) {
      throw new Error(`Error updating password: ${e}`);
    }
  }

  async function updateUserProfile(fullname: string) {
    try {
      if (!firebaseAuth.currentUser) {
        throw new Error('No user signed in');
      }
      await updateProfile(firebaseAuth.currentUser, {
        // Just display name for now, but others may change in the future
        displayName: fullname,
      });
      console.log('Profile updated');
    } catch (e) {
      throw new Error(`Error updating profile: ${e}`);
    }
  }

  return {
    signInWithGoogle,
    signInWithEmail,
    signInWithApple,
    createWithEmail,
    passwordlessSignIn,
    setAuthPersistence,
    signOut,
    //Is this necessary? - DC
    user,
    fbAuthCurrentUser: firebaseAuth.currentUser,
    // resetPassword,
    // sendVerificationEmail,
    // updateUserEmail,
    getSignInMethodsForEmail,
    updateUserPassword,
    updateUserProfile,
  };
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const checkIfAllowedEmailForUser = (email: string, firebase_mode: string) => {
  if (
    firebase_mode === 'EMULATOR' ||
    firebase_mode === 'LIVE' ||
    firebase_mode === 'EULIVE' ||
    firebase_mode === 'EUSANDBOX' ||
    firebase_mode === 'EULAUNCHPAD' ||
    firebase_mode === 'DEMO'
  )
    return true;
  if (
    email.includes('@iwarranty.co') ||
    email.includes('@studiographene.com') ||
    email.includes('@sharklasers.com')
  )
    return true;
  else return false;
};

// WS: I thought it is not worth to import a new library for this
export function parseJwt(token: string | null) {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getPersonasFromToken(
  token: string | null | undefined
): PersonaLink[] {
  if (!token) return [];
  const decoded = parseJwt(token);
  return Object.keys(decoded).filter((key) => isValidPersonaId(key));
}

export function isAdmin(token: string | null | undefined): boolean {
  if (!token) return false;
  const decoded = parseJwt(token);
  return decoded.admin === true;
}

import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                               Get the lexicon                              */
/* -------------------------------------------------------------------------- */

const ICF_GetLexicon = z.object({
  id: z.string(),
});

const OCF_GetLexicon = z.object({
  body: z.record(z.string()),
});

export const CFC_GetLexicon = MakeCloudFunctionSpec(
  'cfcgetlexicon',
  ICF_GetLexicon,
  OCF_GetLexicon
);

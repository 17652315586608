import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';

const ICF_SendEmailTemplateWithBody = z.object({
  email_to: z.string(),
  email_from: z.string(),
  merge_vars: z.array(z.any()),
  body_template_name: z.string(),
  main_template_name: z.string(),
  business_name: z.string(),
});

const OCF_SendEmailTemplateWithBody = z.object({
  email: z.string(),
  status: z.string(),
  reject_reason: z.string(),
  _id: z.string(),
});

export const CFC_SendEmailTemplateWithBody = MakeCloudFunctionSpec(
  'cfcsendemailtemplatewithbody',
  ICF_SendEmailTemplateWithBody,
  OCF_SendEmailTemplateWithBody
);

import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from './base';

const ICF_CheckAPIVersion = ICF_Base;

const OCF_CheckAPIVersion = z.object({
  apiVersion: z.string(),
});

export const CFC_CheckAPIVersion = MakeCloudFunctionSpec(
  'cfccheckapiversion',
  ICF_CheckAPIVersion,
  OCF_CheckAPIVersion
);

import { UserUploadedDocument } from '@rabbit/data/types';
import { FullMetadata } from 'firebase/storage';

export type UploadedFileCategoryShape =
  | 'consumer_claim_evidence'
  | 'consumer_proof_purchase'
  | 'chat_attachment'
  | 'vendable_manuals'
  | 'vendable_images'
  | 'srv_images'
  | 'receipts'
  | 'postage_receipts'
  | 'user_profile_img'
  | 'internal_case_files'
  | 'tenant_logo'
  | 'repairer_profile_image';

export interface UploadedFilesStateShape {
  filesArr: UserUploadedDocument[];
  cat: UploadedFileCategoryShape;
}

// File uploads and previews
export interface DraggedFilePreviewShape {
  type: string;
  url?: string;
}
export interface DraggedFileShape extends Partial<File> {
  id: string;
  extension?: string;
  preview: DraggedFilePreviewShape;
  sizeReadable?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
}

export interface UserUploadedFileShape {
  url: string;
  metadata?: FullMetadata;
  name: string;
}

export type IncomingOrOutgoingUserFileShape = Partial<
  DraggedFileShape & UserUploadedFileShape
>;

export interface DocTypeShape {
  type: 'holding' | 'vendable' | 'case';
  docid: string;
}

export interface FileUploadHandlersShape {
  category: UploadedFileCategoryShape;
  uploadFiles: (
    filesToUpload: File[],
    personaId: string,
    fileCategory: UploadedFileCategoryShape,
    docType?: DocTypeShape
  ) => Promise<void>;
  uploadedFiles?: UploadedFilesStateShape | null;
  uploadedTempFiles?: UploadedFilesStateShape | null;
  deleteFile?: (urlOrPath: string) => Promise<void>;
  clearFileFromState?: (filePath: string) => Promise<void>;
  isUpdating?: boolean;
  uploadProgress?: number | null;
  uploadTaskCat?: UploadedFileCategoryShape | null;
  updateHoldingWithFiles?: (
    holdingId: string,
    filesArr?: UserUploadedDocument[]
  ) => Promise<void>;
  updateVendableWithFiles?: (
    vendableId: string,
    fileType: 'vendable_images' | 'vendable_manuals',
    filesArr?: UserUploadedDocument[]
  ) => Promise<void>;
  clearFileFromHolding?: (holdingId: string, filePath: string) => Promise<void>;
  clearFileFromVendable?: (
    vendableId: string,
    fileType: 'image' | 'manual',
    fileUrl: string
  ) => Promise<void>;
  deleteUnusedTempFiles?: (filesArr?: UserUploadedDocument[]) => Promise<void>;
}

export enum UploadedFileCategories {
  ConsumerCaseEvidence = 'consumer_claim_evidence', // todo change to case here
  ConsumerProofPurchase = 'consumer_proof_purchase',
  ChatAttachment = 'chat_attachment',
  VendableManuals = 'vendable_manuals',
  VendableImages = 'vendable_images',
  SRVImages = 'srv_images',
  PostageReceipts = 'postage_receipts',
  Receipts = 'receipts',
  UserProfileImage = 'user_profile_img',
  InternalCaseFiles = 'internal_case_files',
  ShippingLabel = 'shipping_label',
}

export type PersonaTypeShape = 'Consumer' | 'Manufacturer' | 'Repairer' | null;

export interface OngoingUploadShape {
  key: string;
  progress: number;
  docType?: DocTypeShape;
  category: UploadedFileCategories;
  file: DraggedFileShape;
}

export interface CompletedUploadShape {
  key: string;
  ogFile: DraggedFileShape;
  docType?: DocTypeShape;
  category: UploadedFileCategories;
  error: string | null;
  uploadedFile: UserUploadedDocument | null;
}

export interface UploadQueueStateShape {
  ongoing: OngoingUploadShape[];
  completed: CompletedUploadShape[];
  attached: CompletedUploadShape[];
}

/* ------------------------------ Wrapper types ----------------------------- */
export interface UploadIdentifiersShape {
  personaId: string;
  docType: DocTypeShape | null; // todo: review if we need both docType and category also destructure this
  category: UploadedFileCategories;
}

export interface FileUploaderWrapperProps {
  label: string;
  labelClasses?: string;
  identifiers: UploadIdentifiersShape;
  currentFiles?: any[];
  accepts: string[];
  onUploadCompleted?: (...args: any) => void;
  alterCaseFacts?: (facts: { [key: string]: any }) => Promise<void>;
  shouldAutoUpdateDocs?: boolean;
}

/* ------------------------------- Misc types ------------------------------- */
export interface DeleteFilePropsShape {
  category: UploadedFileCategories;
  holdingId?: string;
  currentFiles?: UserUploadedDocument[];
  alterCaseFacts?: (facts: { [key: string]: any }) => Promise<void>;
  unattached?: boolean;
}

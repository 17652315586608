/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
//import { useField } from 'formik';
// @ts-ignore
import DragFiles from 'react-files';
import UploadedFilePreview from '../../molecules/UploadedFilePreview/UploadedFilePreview';
import {
  DraggedFileShape,
  IncomingOrOutgoingUserFileShape,
  UserUploadedFileShape,
} from '@rabbit/elements/shared-types';
import ImgUploadIcon from '../../assets/icons/image-upload-icon-outline.svg';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';

export interface DragFileUploadProps {
  name: string;
  label: string;
  onChange?: (files: DraggedFileShape[]) => void;
  onError?: (error: Error, file: DraggedFileShape) => any;
  showError?: boolean;
  loading?: boolean;
  accepts?: string[];
  multiple?: boolean;
  clickable?: boolean;
  maxFiles?: number;
  maxFileSize?: number;
  minFileSize?: number;
  className?: string;
  dragActiveClassName?: string;
  previousFiles?: UserUploadedFileShape[];
}

export function DragFileUpload({
  name,
  label,
  className,
  dragActiveClassName,
  maxFileSize,
  multiple,
  accepts,
  onChange,
  onError,
  showError,
  loading,
  clickable,
  maxFiles,
  previousFiles,
}: DragFileUploadProps) {
  //const [field, meta, helpers] = useField(name);
  const [files, setFiles] = useState<IncomingOrOutgoingUserFileShape[]>(
    previousFiles ? [...previousFiles] : []
  );
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const handleChange = (filesArr: DraggedFileShape[]) => {
    setFiles([...files, ...filesArr]);
  };

  const handleRemove = (fileName: string) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const handleError = (error: Error, file: DraggedFileShape) => {
    if (error.message.match('too large')) {
      return setErrorMsg(
        'The selected image exceeds the maximum size allowed. Please upload another file'
      );
    }
    setErrorMsg(error.message);
  };

  const preventPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return;
  };

  // useEffect(() => {
  //   helpers.setValue(files);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files]);

  return (
    <>
      {files && (
        <div className="flex flex-wrap gap-2">
          {files.map((file) => {
            if (!file.name) return null;
            return (
              <UploadedFilePreview
                key={file.name}
                fileName={file.name}
                filePreviewSrc={file.preview?.url ?? file.url ?? undefined}
                handleRemove={() =>
                  file?.name ? handleRemove(file.name) : void 0
                }
              />
            );
          })}
        </div>
      )}
      <DragFiles
        className="relative flex h-32 cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-200 bg-gray-50"
        dragActiveClassName={dragActiveClassName ?? 'bg-gray-300'}
        maxFileSize={maxFileSize ?? 20000000}
        accepts={accepts ?? ['image/*', '.pdf']}
        multiple={multiple ?? true}
        onChange={onChange ?? handleChange}
        onError={onError ?? handleError}
        clickable={clickable ?? true}
        maxFiles={maxFiles ?? 8}
      >
        {loading && (
          <div
            className="t-0 l-0 absolute grid h-full w-full cursor-default place-items-center bg-gray-600 bg-opacity-10"
            onClick={preventPropagation}
            onDrop={preventPropagation}
          >
            <div className="mb-2">
              <LoadingSpinner size={'xs'} />
            </div>
          </div>
        )}
        <div className="item-center my-auto flex flex-col justify-center">
          <div className="flex">
            <img src={ImgUploadIcon} alt="Upload icon" className="mr-5" />
            <div className="font-nunito my-auto text-base text-gray-700">
              {label}
            </div>
          </div>
          <div className="mx-auto pt-3">
            <div className="font-nunito my-auto pl-2 text-sm text-gray-700">
              PDF, PNG or JPG
            </div>
            <div className="font-nunito pt-3 text-sm text-gray-700">
              Max File Size: 30MB
            </div>
          </div>
        </div>
      </DragFiles>
      {errorMsg && showError && (
        <div className="font-nunito text-sm text-red-500">{errorMsg}</div>
      )}
    </>
  );
}

export default DragFileUpload;

import {
  InputProps,
  InputTypeSelectAddonTextSettingsShape,
} from '@rabbit/elements/shared-types';
import InputAutoCompleteVendable from '../InputAutoCompleteVendable/InputAutoCompleteVendable';
import InputCheckbox from '../InputCheckbox/InputCheckbox';
import InputDatePicker from '../InputDatePicker/InputDatePicker';
import InputSelectFile from '../InputSelectFile/InputSelectFile';
import InputTypeCurrency from '../InputTypeCurrency/InputTypeCurrency';
import { InputTypeEmail } from '../InputTypeEmail/InputTypeEmail';
import InputTypePassword from '../InputTypePassword/InputTypePassword';
import InputTypePhone from '../InputTypePhone/InputTypePhone';
import InputTypeSelect from '../InputTypeSelect/InputTypeSelect';
import { InputTypeText } from '../InputTypeText/InputTypeText';
import InputTypeTextArea from '../InputTypeTextArea/InputTypeTextArea';
import InputRichText from '../../../molecules/InputRichText/InputRichText';
import InputAutoCompleteLocationRetailers from '../InputAutoCompleteLocationRetailers/InputAutoCompleteLocationRetailers';
import { InputTypeTime } from '../InputTypeTime/InputTypeTime';
import InputWarrantyTerm from '../InputWarrantyTerm/InputWarrantyTerm';
import InputRadio from '../InputRadio/InputRadio';
import InputPicture from '../InputPicture/InputPicture';
import { useField } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import InputAutoCompleteConsumer from '../InputAutoCompleteConsumer/InputAutoCompleteConsumer';
import InputTypeSelectAddonText from '../InputTypeSelectAddonText/InputTypeSelectAddonText';

function renderChildInput({ type, name, settings, onChange }: InputProps) {
  switch (type) {
    case 'text':
      return <InputTypeText name={name} settings={settings} />;
    case 'email':
      return <InputTypeEmail name={name} settings={settings} />;
    case 'password':
      return <InputTypePassword name={name} settings={settings} />;
    case 'select':
      return <InputTypeSelect name={name} settings={settings} />;
    case 'currency':
      return <InputTypeCurrency name={name} settings={settings} />;
    case 'datepicker':
      return <InputDatePicker name={name} settings={settings} />;
    case 'checkbox':
      return <InputCheckbox name={name} settings={settings} />;
    case 'radio':
      return <InputRadio name={name} settings={settings} />;
    case 'file':
      return (
        <InputSelectFile name={name} settings={settings} onChange={onChange} />
      );
    case 'picture':
      return (
        <InputPicture
          name={name}
          settings={settings}
          //onChange={onChange}
        />
      );
    case 'phone':
      return <InputTypePhone name={name} settings={settings} />;
    case 'textarea':
      return <InputTypeTextArea name={name} settings={settings} />;
    case 'autocomplete-vendable':
      return (
        <InputAutoCompleteVendable
          name={name}
          settings={settings}
          onChange={onChange}
        />
      );
    case 'autocomplete-consumer':
      return (
        <InputAutoCompleteConsumer
          name={name}
          settings={settings}
          onChange={onChange}
        />
      );
    case 'autocomplete-location-retailer':
      return (
        <InputAutoCompleteLocationRetailers
          name={name}
          settings={settings}
          onChange={onChange}
        />
      );
    case 'selectAddonText':
      return (
        <InputTypeSelectAddonText
          name={name}
          settings={settings as InputTypeSelectAddonTextSettingsShape}
        />
      );
    case 'rich-text':
      return (
        <InputRichText name={name} settings={settings} onChange={onChange} />
      );
    case 'time':
      return <InputTypeTime name={name} settings={settings} />;
    case 'warranty_term':
      return <InputWarrantyTerm name={name} settings={settings} />;
    default:
      return null;
  }
}

export function Input({
  type,
  className,
  label,
  labelStyles,
  name,
  settings,
  onChange,
}: InputProps) {
  const [, meta] = useField(name);
  // Class name definitions
  const baseLabelStyle = 'font-nunito mb-2 text-base text-gray-900 ';
  const baseMessageStyle = 'font-nunito text-base text-gray-500 ';

  return (
    <div className={className ?? 'flex w-full flex-col'}>
      {label && (
        <label
          htmlFor={name}
          className={
            baseLabelStyle +
            (meta?.error ? 'text-red-500' : '') +
            (labelStyles ? labelStyles : '')
          }
        >
          {label}
        </label>
      )}
      {renderChildInput({ type, name, settings, onChange })}
      {(label || settings.hint) && (
        <div className={baseMessageStyle + (meta?.error ? 'text-red-500' : '')}>
          {meta?.error ? (
            <div className="font-nunito mt-2 flex items-start text-base text-red-500">
              <ExclamationCircleIcon className="h-5 w-5" />
              <span className="ml-1">
                {typeof meta.error == 'object'
                  ? (Object.values(meta.error)[0] as any)
                  : meta.error}
              </span>
            </div>
          ) : (
            <div>
              {settings.hint && (
                <div className="font-nunito mt-2 text-base text-gray-500">
                  {settings.hint}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                      Consumer persona existence checks                     */
/* -------------------------------------------------------------------------- */

/* ------------------------------- Using email ------------------------------ */

const ICF_CheckConsumerPrivateExistence_WithEmail = z.object({
  email: z.string(),
});

const OCF_CheckConsumerPrivateExistence_WithEmail = z.object({
  exists: z.boolean(),
});

export const CFC_CheckConsumerPrivateExistence_WithEmail =
  MakeCloudFunctionSpec(
    'cfccheckconsumerprivateexistencewithemail',
    ICF_CheckConsumerPrivateExistence_WithEmail,
    OCF_CheckConsumerPrivateExistence_WithEmail
  );

/* -------------------------------------------------------------------------- */
/*                       Fetch consumer private document                      */
/* -------------------------------------------------------------------------- */

/* -------------------------------- Using id -------------------------------- */

export const ICF_FetchConsumerPrivate_WithId = z.object({
  consumerId: z.string(),
});

export const OCF_FetchConsumerPrivate_WithId = z.object({
  consumer_private: z.any(),
  //   consumer_private: Z_DTConsumer_Private,
});

export const CFC_FetchConsumerPrivate_WithId = MakeCloudFunctionSpec(
  'cfcfetchconsumerprivatewithid',
  ICF_FetchConsumerPrivate_WithId,
  OCF_FetchConsumerPrivate_WithId
);

/* ------------------------------- Using email ------------------------------ */
export const ICF_FetchConsumerPrivate_WithEmail = z.object({
  email: z.string(),
});

export const OCF_FetchConsumerPrivate_WithEmail = z.object({
  consumer_private: z.any(),
  //   consumer_private: Z_DTConsumer_Private,
});

export const CFC_FetchConsumerPrivate_WithEmail = MakeCloudFunctionSpec(
  'cfcfetchconsumerprivatewithemail',
  ICF_FetchConsumerPrivate_WithEmail,
  OCF_FetchConsumerPrivate_WithEmail
);

import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import {
  Address,
  DateTime,
  Money,
  ImageUrls,
  UploadedImage,
  UploadedDocumentUrls,
  Z_Money,
  Z_UploadedImage,
  Z_Address,
} from '../base/basic';
import {
  ConsumerLink,
  ManufacturerLink,
  RetailerLink,
  Z_ConsumerLink,
  Z_RetailerLink,
} from '../persona';
import {
  HoldingExtraService,
  HoldingLink,
  SelfRegistration,
  ShopifyLinks,
  WarrantyDuration,
  Z_ShopifyLinks,
  Z_WarrantyDuration,
} from './holding';
import { VendableLink, Z_VendableLink } from './vendable';
import { WarrantyTypes } from './warranty-template';
import { DeciderOutput, Z_DeciderOutput } from '../decider/types';
import {
  AppliedWarrantyOption,
  WarrantyStatus,
  Z_AppliedWarrantyOption,
  Z_Warranty,
  Z_WarrantyStatus,
} from './warranty';
import { OurDateTime, z } from '@rabbit/utils/ts';

/** A way to capture the various warranties and their terms */
export type Warranty = {
  /** What type of warranty it is */
  type: WarrantyTypes;

  /** How long the warranty is for */
  duration: WarrantyDuration;

  /** How much they paid for the warranty (eg extended warranty) */
  price?: Money;

  /** Where they purchased the warranty (eg extended warranty) */
  purchase_location?: RetailerLink;

  /** Evidence of the warranty */
  evidence?: UploadedDocumentUrls;

  // copied from warranty
  startDate?: number;
  endDate?: number;
  templateTitle?: string;
  appliedOptions?: AppliedWarrantyOption[];
  retailerLink?: RetailerLink;
  retailerName?: string;
  retailerEmail?: string;
  retailerPhone?: string;
  retailerAddresses?: Address[];
  // Eventually this will be the only warranty info in the holding document, but for now we need to support both the old and new approaches. - DC
  warrantyLink?: string;
  warrantorLink?: string;
  warrantorClaimEmail?: string;
  warrantorClaimPhone?: string;
  // TODO: make mandatory once everything else uses it
  decider?: DeciderOutput;
  status: WarrantyStatus;
  salesRep?: string;
  registeredTime: number;
};

/* -------------------------------------------------------------------------- */
/*                                Holding Proxy                               */
/* -------------------------------------------------------------------------- */

/** A proxy document for recording the most pertinent information about a given holding,
 *  held in a compiled form for easy querying.
 *  Right now this is based on the needs of the /registrations and /customers tables on Sage
 *  plus the product cards on Olive, with a few added fields that might be useful in the future
 *  */
export interface DTHoldingProxy extends NoSqlDoc {
  // DocIds
  /** ConsumerLink: The consumer that purchased the holding */
  consumer: ConsumerLink;

  /** The vendable identified in the Vendadex.  */
  vendable: VendableLink;

  holdingLink: HoldingLink;

  // Consumer data
  /** The consumer's name */
  consumer_name?: string;

  /** The consumer's email */
  consumer_email?: string;

  // Holding data
  /** The moment that the registration took place. */
  register_time: DateTime;

  /** The moment that the item was purchased. */
  purchase_time: DateTime;

  /** Array of warranties applicable to the holding */
  warranties: Warranty[];

  /** If the holding has a warranty that is not voided */
  hasWarranty: boolean;

  /** Array of warranty retailer links, needed for querying */
  warrantyRetailerLinks?: RetailerLink[];

  /** The moment that the holding was created. */
  created_time: DateTime;

  /** How much they paid for it */
  purchase_price?: Money;

  /** Where they purchased it */
  purchase_location?: RetailerLink;

  /** The name of the retailer as inputted by the user, in case it was not listed in the retailer database */
  purchase_location_other?: string;

  /* Country where holding was purchased */
  purchase_country?: string;

  /** An array of open cases represented here by their ids */
  active_cases?: string[];

  /** An array of closed cases represented here by their ids */
  closed_cases?: string[];

  /** Serial number */
  serial?: string;

  /** Questionnaire entries that manufacturers may ask for */
  questionnaire?: { [key: string]: string };

  /** If the user has deleted this holding. Document not actually removed for now as a safeguard, but this practice needs to be evaluated in regards to GDPR -dc */
  deleted?: boolean;

  /** Additional services sold for the given holding that are unrelated to warranties, e.g. sealing in NuCover  */
  extraServices?: HoldingExtraService[];

  /* ------------------------------ Vendable data ----------------------------- */

  /** Human readable descriptive short headline title of the vendable. May be shown on its own. */
  title: string;

  /** Human readable longer name of the vendable. Will not be shown with the title. */
  full?: string;

  /** The main category's name, to be displayed to the user */
  category?: string;

  /** Url of image. If array then it is multiple images with the first being the headline. */
  img?: ImageUrls;

  /** Array of uploaded image info. If multiple items the first is the headline. */
  images?: UploadedImage[];

  /** Brand name as displayed to the consumer */
  brand?: string;

  /** Manufacturer Part Number (model number) */
  mpn?: string;

  /** Link to the user manual(s) */
  manual?: UploadedDocumentUrls;

  /** Structure for self-registration of an item that is not in the database. The data is a subset of Vendable. */
  self_registration?: SelfRegistration | null;

  /* ------------------------------ Shopify data ------------------------------ */
  /** Shopify-related ids if holding was generated after a Shopify purchase */
  shopifyLinks?: ShopifyLinks | null;

  // Flags
  /** If the user should be sent the holding registration email triggered on creation. Defaults to true. */
  send_registration_email: boolean;

  /** ManufacturerLink:: Manufacturer - their persona */
  mfr: ManufacturerLink;
}

export const FBD_HoldingProxy = MakeFBDocType<DTHoldingProxy>({
  name: 'Holding_Proxy',
  collection: 'holding_proxy',
  empty: () => {
    const result: DTHoldingProxy = {
      ...Empty_NoSqlDoc(),
      consumer: '',
      vendable: '',
      title: '',
      created_time: OurDateTime.nowUTCTimestamp(),
      warranties: [],
      register_time: OurDateTime.nowUTCTimestamp(),
      purchase_time: 0,
      send_registration_email: true,
      mfr: '',
      holdingLink: '',
      hasWarranty: false,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED('Holding_Proxy key should be HOLDINGID_VENDABLEID'),
});

// TODO: REVIEW
export const Z_HoldingProxyWarranties = z.object({
  type: z.string(),
  duration: Z_WarrantyDuration.optional(),
  price: Z_Money.optional(),
  purchase_location: Z_RetailerLink.optional(),
  evidence: z.array(z.string()).optional(),
  startDate: z.number().optional(),
  endDate: z.number().optional(),
  templateTitle: z.string().optional(),
  appliedOptions: z.array(Z_AppliedWarrantyOption).optional(),
  retailerLink: Z_RetailerLink.optional(),
  retailerName: z.string().optional(),
  retailerEmail: z.string().optional(),
  retailerPhone: z.string().optional(),
  retailerAddresses: z.array(Z_Address).optional(),
  warrantyLink: z.string().optional(),
  warrantorLink: z.string().optional(),
  warrantorClaimEmail: z.string().optional(),
  warrantorClaimPhone: z.string().optional(),
  decider: Z_DeciderOutput.optional(), // TODO this needs fixing
  status: Z_WarrantyStatus,
  salesRep: z.string().optional(),
  registeredTime: z.number(),
});

export const Z_HoldingProxy = z.object({
  consumer: Z_ConsumerLink,
  vendable: Z_VendableLink,
  holdingLink: z.string(),
  consumer_name: z.string().optional(),
  consumer_email: z.string().optional(),
  register_time: z.number(),
  purchase_time: z.number(),
  warranties: z.array(Z_HoldingProxyWarranties).optional(),
  hasWarranty: z.boolean(),
  warrantyRetailerLinks: z.array(z.string()).optional(),
  created_time: z.number(),
  purchase_price: Z_Money.optional(),
  purchase_location: Z_RetailerLink.optional(),
  purchase_location_other: z.string().optional(),
  purchase_country: z.string().optional(),
  active_cases: z.array(z.string()).optional(),
  closed_cases: z.array(z.string()).optional(),
  serial: z.string().optional(),
  questionnaire: z.record(z.string()).optional(),
  deleted: z.boolean().optional(),
  extraServices: z
    .array(
      z.object({
        service: z.string(),
        price: Z_Money,
      })
    )
    .optional(),
  title: z.string(),
  full: z.string().optional(),
  category: z.string().optional(),
  img: z.array(z.string()).optional(),
  images: z.array(Z_UploadedImage).optional(),
  brand: z.string().optional(),
  mpn: z.string().optional(),
  manual: z.array(z.string()).optional(),
  self_registration: z
    .object({
      title: z.string(),
      img: z.array(z.string()),
      desc: z.string(),
      form: z.record(z.string()),
    })
    .optional()
    .nullable(),
  shopifyLinks: Z_ShopifyLinks.optional().nullable(),
  send_registration_email: z.boolean(),
  mfr: z.string(),
});

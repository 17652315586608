import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Shopify_Product_Shape, Shopify_Variant_Shape } from './base-types';

/**
 * The version of the product schema we use in the database, which is identical to the
 * Shopify_Product_Shape but removes unnecessary fields from the variants key
 */
export interface Shopify_Lifted_Product_Shape extends Shopify_Product_Shape {
  variants?: Shopify_Variant_Shape[];
}

export interface ShopifyVendableVersionHistory {
  version: number;
  product: Shopify_Lifted_Product_Shape;
  tcreate: number;
  tupdate: number;
}

// todo: this might turn into its own collection, here or in Rabbit - dc
export interface ShopifyCategory {
  /** The id of the category node in Shopify GraphQL format, e.g. "gid://shopify/ProductTaxonomyNode/5173" */
  id: string;
  /** The name of the category node, e.g. "Snowboards" */
  name: string;
  /** Full product taxonomy in string format, e.g. "Sporting Goods > Outdoor Recreation > Winter Sports & Activities > Skiing & Snowboarding > Snowboards" */
  fullName: string;
}

/* -------------------------------------------------------------------------- */
/*                           Shopify Vendable Schema                          */
/* -------------------------------------------------------------------------- */

export interface DTShopifyVendable extends NoSqlDoc {
  /**
   * The Shopify shop URI, which serves as the unique identifier for the shop. E.g bananamart.myshopify.com
   */
  shopLink: string;

  /* Original Shopify fields */
  product: Shopify_Lifted_Product_Shape;

  /* The category this product belongs to, according to the Shopify taxonomy */
  category: ShopifyCategory | null;

  /* Has the product been deprecated, aka removed from the store? */
  deprecated: boolean;

  /* Is this product a draft? */
  draft: boolean;

  /* The version number for this vendable. Starts at 1 */
  version: number;

  /* Doc ids for the exported vendables, aka what we actually send over to Rabbit */
  exportIds: string[];

  /* The version history for this vendable */
  versionHistory?: ShopifyVendableVersionHistory[];
}

export const FBD_ShopifyVendable = MakeFBDocType<DTShopifyVendable>({
  name: 'Shopify_Vendable',
  collection: 'shopify_vendable',
  empty: () => {
    const result: DTShopifyVendable = {
      ...Empty_NoSqlDoc(),
      shopLink: '',
      product: {} as Shopify_Lifted_Product_Shape,
      deprecated: false,
      version: 1,
      draft: false,
      exportIds: [],
      category: null,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'Shopify_Vendable key should be SHOPNAME_SHOPIFYPRODUCTID'
  ),
});

import { z, schema } from '@rabbit/utils/ts';
export * from './identity';
export * from './persona';
// Export all the personas
export * from './consumer';
export * from './manufacturer';
export * from './repairer';
export * from './retailer';
export * from './warrantor';
export * from './warranty-dealer';

// The persona registry

import {
  FBDocType,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { FBD_Consumer_Private, FBD_Consumer_Public } from './consumer';
import {
  FBD_Manufacturer_Private,
  FBD_Manufacturer_Public,
} from './manufacturer';
import { FBD_Repairer_Private, FBD_Repairer_Public } from './repairer';
import { FBD_Retailer_Private, FBD_Retailer_Public } from './retailer';
import { DTPersona, DTPersonaPublic } from './persona';
import { FBD_Warrantor_Private, FBD_Warrantor_Public } from './warrantor';
import {
  FBD_WarrantyDealer_Private,
  FBD_WarrantyDealer_Public,
} from './warranty-dealer';
import { TenantLink } from '../lib/tenant';

export const PersonaTypeFullKeyValues = {
  consumer: 'consumer',
  manufacturer: 'manufacturer',
  repairer: 'repairer',
  retailer: 'retailer',
  warrantor: 'warrantor',
  warrantyDealer: 'warrantyDealer',
} as const;

export type PersonaTypeFullKey =
  (typeof PersonaTypeFullKeyValues)[keyof typeof PersonaTypeFullKeyValues];

export const Z_PersonaTypeFullKey = z.enum([
  'consumer',
  'manufacturer',
  'repairer',
  'retailer',
  'warrantor',
  'warrantyDealer',
]);

export enum PersonaTypeSingleLetter {
  Consumer = 'C',
  Repairer = 'R',
  Manufacturer = 'M',
  Retailer = 'S', // S for SHOP
  Warrantor = 'W',
  WarrantyDealer = 'D', // D for DEALER
}

export const Z_PersonaTypeSingleLetter = schema<PersonaTypeSingleLetter>()(
  z.nativeEnum(PersonaTypeSingleLetter)
);

export const PersonaIdTypeSplitter = ':';

type PersonaRegistryEntry<
  PRIVATE_TYPE extends NoSqlDoc,
  PUBLIC_TYPE extends NoSqlDoc
> = {
  name: string;
  short_prefix: PersonaTypeSingleLetter;
  FBD_private: FBDocType<PRIVATE_TYPE>;
  FBD_public: FBDocType<PUBLIC_TYPE>;
};

function MakePersonaEntry<
  PRIVATE_TYPE extends NoSqlDoc,
  PUBLIC_TYPE extends NoSqlDoc
>(
  name: string,
  short_prefix: PersonaTypeSingleLetter,
  FBD_private: FBDocType<PRIVATE_TYPE>,
  FBD_public: FBDocType<PUBLIC_TYPE>
): PersonaRegistryEntry<PRIVATE_TYPE, PUBLIC_TYPE> {
  return {
    name,
    short_prefix,
    FBD_private,
    FBD_public,
  };
}

export type FBD_Persona_Anonymous_Private = ReturnType<
  typeof MakeFBDocType<DTPersona>
>;

export type FBD_Persona_Anonymous_Public = ReturnType<
  typeof MakeFBDocType<DTPersonaPublic>
>;

export const FBD_Null = MakeFBDocType<any>({
  name: 'Null (indicating that there is no type)',
  collection: 'null',
  empty: () => {
    return null;
  },
  keygen: FBD_Keygen_BANNED('What are you doing?'),
});

export const PersonaRegistry: {
  [key in PersonaTypeFullKey]: PersonaRegistryEntry<any, any>;
} = {
  consumer: MakePersonaEntry(
    'Consumer',
    PersonaTypeSingleLetter.Consumer,
    FBD_Consumer_Private,
    FBD_Consumer_Public
  ),
  manufacturer: MakePersonaEntry(
    'Manufacturer',
    PersonaTypeSingleLetter.Manufacturer,
    FBD_Manufacturer_Private,
    FBD_Manufacturer_Public
  ),
  repairer: MakePersonaEntry(
    'Repairer',
    PersonaTypeSingleLetter.Repairer,
    FBD_Repairer_Private,
    FBD_Repairer_Public
  ),
  retailer: MakePersonaEntry(
    'Retailer',
    PersonaTypeSingleLetter.Retailer, // shop
    FBD_Retailer_Private,
    FBD_Retailer_Public
  ),
  warrantor: MakePersonaEntry(
    'Warrantor',
    PersonaTypeSingleLetter.Warrantor,
    FBD_Warrantor_Private,
    FBD_Warrantor_Public
  ),
  warrantyDealer: MakePersonaEntry(
    'Warranty Dealer',
    PersonaTypeSingleLetter.WarrantyDealer,
    FBD_WarrantyDealer_Private,
    FBD_WarrantyDealer_Public
  ),
};

export const PersonaShortPrefixToRegistryEntry: {
  [key: string]: PersonaRegistryEntry<any, any>;
} = {};
(() => {
  const entries = Object.values(PersonaRegistry);
  entries.forEach((entry) => {
    const castedEntry = entry as unknown as PersonaRegistryEntry<any, any>;
    // : ReturnType<typeof MakePersonaEntry<any,any>>
    PersonaShortPrefixToRegistryEntry[castedEntry.short_prefix] = castedEntry;
  });
})();

export type OneEachOptionalPersonaLink = {
  [K in keyof typeof PersonaRegistry]?: string;
};

// This zod is a bit suck.
export const Z_OneEachOptionalPersonaLink =
  schema<OneEachOptionalPersonaLink>()(
    z.object({
      consumer: z.string().optional(),
      manufacturer: z.string().optional(),
      repairer: z.string().optional(),
      retailer: z.string().optional(),
      warrantor: z.string().optional(),
      warrantyDealer: z.string().optional(),
    })
  );

export const PersonaSingleLetterToOneEach: {
  [key in PersonaTypeSingleLetter]: keyof OneEachOptionalPersonaLink;
} = {
  [PersonaTypeSingleLetter.Consumer]: 'consumer',
  [PersonaTypeSingleLetter.Repairer]: 'repairer',
  [PersonaTypeSingleLetter.Manufacturer]: 'manufacturer',
  [PersonaTypeSingleLetter.Retailer]: 'retailer',
  [PersonaTypeSingleLetter.Warrantor]: 'warrantor',
  [PersonaTypeSingleLetter.WarrantyDealer]: 'warrantyDealer',
};

/** Generates a personaLink string for the provided tenant (ie their root persona link of the given type) */
export function GenerateTenantRootPersonaLink(
  personaType: PersonaTypeFullKey,
  tenantLink: TenantLink
) {
  const personaLetter = PersonaRegistry[personaType].short_prefix;
  return personaLetter + PersonaIdTypeSplitter + tenantLink;
}

export function GetSingleLetterPersonaType(personaDocId: string) {
  return personaDocId.split(PersonaIdTypeSplitter)[0];
}

function GetPrivateFBDForPersona(personaDocId: string) {
  const shortcode = GetSingleLetterPersonaType(personaDocId);
  const regEntry = PersonaShortPrefixToRegistryEntry[shortcode];
  return regEntry.FBD_private;
}

function GetPublicFBDForPersona(personaDocId: string) {
  const shortcode = GetSingleLetterPersonaType(personaDocId);
  const regEntry = PersonaShortPrefixToRegistryEntry[shortcode];
  return regEntry.FBD_public;
}

export function GetPersonaTypeFromDocId(personaDocId: string) {
  const shortcode = GetSingleLetterPersonaType(
    personaDocId
  ) as PersonaTypeSingleLetter;
  const personaType = PersonaSingleLetterToOneEach[shortcode];
  return personaType;
}

export function GetAnonymousPrivateFBDForPersona(
  personaDocId: string
): FBD_Persona_Anonymous_Private {
  const privateFBD = GetPrivateFBDForPersona(personaDocId);
  return privateFBD as FBD_Persona_Anonymous_Private;
}

export function GetAnonymousPublicFBDForPersona(
  personaDocId: string
): FBD_Persona_Anonymous_Public {
  const publicFBD = GetPublicFBDForPersona(personaDocId);
  return publicFBD as FBD_Persona_Anonymous_Public;
}

/**
 * Checks if a personaId is valid, aka, either C(onsumer), M(anufacturer), or R(epairer)
 * @param personaId
 * @returns
 */
export const isValidPersonaId = (personaId: string) => {
  if (!personaId) return false;
  if (
    (!personaId.startsWith(PersonaTypeSingleLetter.Consumer) &&
      !personaId.startsWith(PersonaTypeSingleLetter.Manufacturer) &&
      !personaId.startsWith(PersonaTypeSingleLetter.Retailer) &&
      !personaId.startsWith(PersonaTypeSingleLetter.Repairer) &&
      !personaId.startsWith(PersonaTypeSingleLetter.Warrantor) &&
      !personaId.startsWith(PersonaTypeSingleLetter.WarrantyDealer)) ||
    personaId[1] !== PersonaIdTypeSplitter
  ) {
    return false;
  } else return true;
};

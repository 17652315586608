import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { ICF_Base } from '../base';

/* -------------------------------------------------------------------------- */
/*                 Checking Sage Persona existence for an user                */
/* -------------------------------------------------------------------------- */

/* ------------------------- Using email address ------------------------- */

export const ICF_CheckSagePersonaExistence_WithEmail = z
  .object({
    email: z.string(),
  })
  .merge(ICF_Base);

export const OCF_CheckSagePersonaExistence_WithEmail = z.object({
  exists: z.boolean(),
});

export const CFC_CheckSagePersonaExistence_WithEmail = MakeCloudFunctionSpec(
  'cfcchecksagepersonaexistencewithemail',
  ICF_CheckSagePersonaExistence_WithEmail,
  OCF_CheckSagePersonaExistence_WithEmail
);

import ProgressBar from '../../ProgressBar/ProgressBar';
import { RepairDataShape } from '../../../molecules/CardGeneral/CardGeneral';
import { DTHoldingProxy } from '@rabbit/data/types';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';

export interface CardContentProps {
  holdingData?: DTHoldingProxy | ConsumerHoldingSummaryShape;
  repairData?: RepairDataShape;
  appInfo?: any;
}

const dynamicStyles = {
  container: 'flex flex-col items-start font-nunito flex-1',
  title: 'font-bold text-base leading-4 lg:leading-5 text-ellipsis',
  subtitle: 'font-normal text-sm pt-[5px] leading-2 capitalize',
  text: 'font-light text-sm pt-1.5 leading-3 md:leading-4',
};

export function CardContent({
  holdingData,
  repairData,
  appInfo,
}: CardContentProps) {
  return (
    <div className={dynamicStyles.container}>
      {repairData && (
        <>
          <div className="mb-[10px] w-full">
            {/* <ProgressBar width={repairData.repairProgress} /> */}
            <ProgressBar width={'50'} />
          </div>
          <p className={dynamicStyles.title}>{repairData.title}</p>
          {repairData.lastStatusUpdate && (
            <p className={dynamicStyles.subtitle}>
              Last updated: {repairData.lastStatusUpdate}
            </p>
          )}

          <p className={dynamicStyles.text}>{repairData.purchase_location}</p>
        </>
      )}
      {holdingData && (
        <>
          <p className={dynamicStyles.title}>{getHoldingTitle(holdingData)}</p>
          <p className={`${dynamicStyles.subtitle} opacity-50`}>
            {getHoldingCategory(holdingData)}
          </p>
          {!holdingData.self_registration &&
            appInfo?.name
              .toLowerCase()
              .includes(String(holdingData?.brand)?.toLowerCase()) && (
              <img src={appInfo.logo} alt={appInfo.name} className="h-8 pt-1" />
            )}
          {(holdingData.self_registration ||
            !appInfo?.name
              .toLowerCase()
              .includes(String(holdingData?.brand)?.toLowerCase())) && (
            <p className={`${dynamicStyles.subtitle}`}>
              {getHoldingBrand(holdingData)}
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default CardContent;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const getHoldingTitle = (
  holdingData: DTHoldingProxy | ConsumerHoldingSummaryShape
) => {
  if (
    holdingData.self_registration &&
    typeof holdingData.self_registration !== 'boolean'
  ) {
    return holdingData?.self_registration?.title;
  } else return holdingData?.title;
};

const getHoldingCategory = (
  holding: DTHoldingProxy | ConsumerHoldingSummaryShape
) => {
  if (
    holding.self_registration &&
    typeof holding.self_registration !== 'boolean'
  ) {
    return holding?.self_registration?.category_title?.replace(/-/g, ' ');
  } else {
    if (holding.category && Array.isArray(holding.category)) {
      return holding.category[0].replace(/-/g, ' ');
    } else {
      return holding.category?.replace(/-/g, ' ');
    }
  }
};

const getHoldingBrand = (
  holdingData: DTHoldingProxy | ConsumerHoldingSummaryShape
) => {
  if (
    holdingData.self_registration &&
    typeof holdingData.self_registration !== 'boolean'
  ) {
    return holdingData?.self_registration?.brand;
  } else return holdingData?.brand;
};

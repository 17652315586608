import {
  ICF_RegisterCase,
  OCF_RegisterCase,
  CFC_RegisterCase,
} from '@rabbit/bizproc/core';
import { APICallResultBase } from '@rabbit/elements/shared-types';
import { z } from 'zod';

export async function CaseFlow_InitiateCase({
  requestId,
  apiVersion,
  origin,
  caseType,
  tenantLink,
  holdingLink,
  consumerLink,
  warrantorLink,
  formData,
}: z.infer<typeof ICF_RegisterCase>): Promise<
  { data: z.infer<typeof OCF_RegisterCase> } & APICallResultBase
> {
  const result = await CFC_RegisterCase.call({
    requestId,
    apiVersion,
    origin,
    caseType,
    tenantLink,
    holdingLink,
    consumerLink,
    warrantorLink,
    formData,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}

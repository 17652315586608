import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export interface TabsGenericProps {
  items: Array<TabItemProps>;
  query?: boolean;
  hasActivityUpdate?: boolean;
  hasChatUpdate?: boolean;
  hugTabs?: boolean;
}

export interface TabItemProps {
  label: string;
  children: React.ReactNode;
  disabled?: boolean;
}

export function TabsGeneric(props: TabsGenericProps) {
  const {
    items,
    query = true,
    hasActivityUpdate,
    hasChatUpdate,
    hugTabs,
  } = props;
  const navigate = useNavigate();

  const [activeTab, setActive] = useState<number>(0);
  const [paramValue, setParamValue] = useState<string>('');

  const QUERY_PARAM = 'tab';
  const searchParams = new URLSearchParams(window.location.search);

  const setActiveTab = (value: string) => {
    for (let i = 0; i < items.length; i++) {
      const label = String(items[i].label).toLocaleLowerCase();
      if (value.toLocaleLowerCase() === label) {
        setActive(i);
        if (query) {
          navigate({
            search: createSearchParams({ tab: label }).toString(),
          });
        }
      }
    }
  };

  useEffect(() => {
    const currentValue = searchParams.get(QUERY_PARAM) || '';
    if (currentValue !== paramValue) {
      setParamValue(currentValue);
      setActiveTab(currentValue);
    }
  }, [window.location.search, QUERY_PARAM, paramValue]);

  return (
    <div className={classNames('', { 'flex flex-col items-center': hugTabs })}>
      {items && items.length > 1 && (
        <div className={classNames(
          "border-1 mb-5 flex justify-around overflow-hidden overflow-x-auto rounded-md border text-gray-500 shadow-sm lg:inline-flex lg:justify-start",
          {
            "w-full": hugTabs !== true,
          }
        )}>
          {items?.map((item, index) => (
            <div
              key={index}
              className={classNames(
                'lg:px-auto flex-grow cursor-pointer border-gray-200 py-4 text-center',
                {
                  'bg-gray-50': activeTab === index,
                  'border-r-[1px]': index !== items.length - 1,
                  'opacity-50 cursor-auto': item.disabled,
                }
              )}
              onClick={() => !item.disabled && setActiveTab(item.label)}
            >
              <div
                className={classNames(
                  'flex items-center justify-center gap-2 font-bold',
                  {
                    'px-16': hugTabs,
                  }
                )}
              >
                {item.label}
                {item.label === 'Chat' && hasChatUpdate && (
                  <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                )}
                {item.label === 'Activity' && hasActivityUpdate && (
                  <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="w-full">{items && items[activeTab].children}</div>
    </div>
  );
}

export default TabsGeneric;

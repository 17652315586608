import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { DateTime } from '../base/basic';
import {
  DTVendable,
  DTVendable_Private,
  Z_Vendable,
  Z_Vendable_Private,
} from './vendable';
import { ManufacturerLink } from '../persona';
import { z } from 'zod';

/* -------------------------------------------------------------------------- */
/*                              Vendable History                              */
/* -------------------------------------------------------------------------- */

export interface VendableHistoryBasic {
  version: number;
  tupdate: DateTime;
  updatedBy: string;
}

export interface VendablePublicHistoryItem extends VendableHistoryBasic {
  content: DTVendable;
}

export interface VendablePrivateHistoryItem extends VendableHistoryBasic {
  content: DTVendable_Private;
}

/** Version history for the vendable (public and private) documents. */
export interface DTVendable_History extends NoSqlDoc {
  /** The current version number */
  version: number;

  /** Who last updated this vendable. TenantLink or PersonaLink */
  updatedBy: string;

  /** The manufacturer of the vendable */
  mfr: ManufacturerLink;

  /** Array of the history for the public vendable document */
  public: VendablePublicHistoryItem[];

  /** Array of the history for the private vendable document */
  private: VendablePrivateHistoryItem[];

  /** Is this vendable active? I.e, is it available for search on Sage? */
  active: boolean;
}

export const FBD_Vendable_History = MakeFBDocType<DTVendable_History>({
  name: 'Vendable History',
  collection: 'vendable_history',
  empty: () => {
    const result: DTVendable_History = {
      ...Empty_NoSqlDoc(),
      version: 1,
      public: [],
      private: [],
      updatedBy: '', // 'tenantLink' or 'personaLink
      mfr: '', // 'manufacturerLink
      active: false, // needs to be activated manually by the manufacturer
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related vendable.'
  ),
});

export const Z_Vendable_History = z.object({
  version: z.number(),
  updatedBy: z.string(),
  mfr: z.string(),
  public: z.array(
    z.object({
      version: z.number(),
      tupdate: z.number(),
      updatedBy: z.string(),
      content: Z_Vendable,
    })
  ),
  private: z.array(
    z.object({
      version: z.number(),
      tupdate: z.number(),
      updatedBy: z.string(),
      content: Z_Vendable_Private,
    })
  ),
  active: z.boolean(),
});

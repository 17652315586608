/** THIS IS ONLY IMPORTED FOR TYPE INFORMATION */
// import * as Firestore from '@firebase/firestore';

export interface FBFCollectionRef<T, SECRET = any> {
  _docType?: T;
  _secret: SECRET;
}

export interface FBFDocumentRef<T, SECRET = any> {
  _docType?: T;
  id: string;
  _secret: SECRET;
}

export interface FBFDefaultDocumentType {
  /** A mapping between a field and its value. */
  [field: string]: any;
}

export interface FBFDocumentResult<T = FBFDefaultDocumentType> {
  body: T | null;
}

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

type QueryConstraint =
  | {
      kind: 'where';
      field: string;
      operator: '==' | '<' | '<=' | '>' | '>=' | 'array-contains' | 'in' | '!=';
      value: any;
    }
  | {
      kind: 'orderBy';
      field: string;
      direction: 'asc' | 'desc';
    }
  | {
      kind: 'limit';
      limit: number;
    };
export abstract class FBFQuery<T = FBFDefaultDocumentType> {
  constraints: QueryConstraint[] = [];
  collection: string;

  constructor(collection: string) {
    this.collection = collection;
  }

  where(
    field: string,
    operator: '==' | '<' | '<=' | '>' | '>=' | 'array-contains' | 'in' | '!=',
    value: any
  ) {
    this.constraints.push({ kind: 'where', field, operator, value });
    return this;
  }

  orderBy(field: string, direction: 'asc' | 'desc') {
    this.constraints.push({ kind: 'orderBy', field, direction });
    return this;
  }

  limit(limit: number) {
    this.constraints.push({ kind: 'limit', limit });
    return this;
  }

  abstract get(): Promise<FBFDocumentResult<T>[]>;

  async getContents(): Promise<T[]> {
    const output = await this.get();
    const result: T[] = [];
    for (const x of output) {
      if (x.body) {
        result.push(x.body);
      }
    }
    return result;
  }
}

export type FirebaseFunctionsSet = {
  collection: <T = FBFDefaultDocumentType>(name: string) => FBFCollectionRef<T>;
  doc: <T = FBFDefaultDocumentType>(
    path: string,
    ...pathSegments: string[]
  ) => FBFDocumentRef<T>;
  getDoc: <T = FBFDefaultDocumentType>(
    document: FBFDocumentRef<T>
  ) => Promise<FBFDocumentResult<T>>;
  setDoc: <T = FBFDefaultDocumentType>(
    document: FBFDocumentRef<T>,
    data: T
  ) => Promise<void>;
  query: <T = FBFDefaultDocumentType>(collection: string) => FBFQuery<T>;
  deleteDoc: <T = FBFDefaultDocumentType>(
    document: FBFDocumentRef<T>
  ) => Promise<void>;
  cloudcall: (name: string, params: any) => Promise<any>;
};

function stub(type: string) {
  return () => {
    throw new Error(`FirebaseWrapper: Not implemented (${type})`);
  };
}

export const FBF: FirebaseFunctionsSet = {
  collection: stub('collection'),
  doc: stub('doc'),
  getDoc: stub('getDoc'),
  setDoc: stub('setDoc'),
  query: stub('query'),
  deleteDoc: stub('deleteDoc'),
  cloudcall: stub('cloudcall'),
};

export function ApplyFirebaseFunctions(alterations: FirebaseFunctionsSet) {
  for (const x in alterations) {
    FBF[x as keyof FirebaseFunctionsSet] = alterations[
      x as keyof FirebaseFunctionsSet
    ] as any;
  }
}

/* -------------------------------------------------------------------------- */
/*                    Firebase Cloud Function Registration                    */
/* -------------------------------------------------------------------------- */

export declare type HttpsCallable<
  RequestData = unknown,
  ResponseData = unknown
> = (data?: RequestData | null) => Promise<HttpsCallableResult<ResponseData>>;
export declare interface HttpsCallableResult<ResponseData = unknown> {
  /**
   * Data returned from callable function.
   */
  readonly data: ResponseData;
}

export function FBF_CloudCallable<
  RequestData = unknown,
  ResponseData = unknown
>(name: string): HttpsCallable<RequestData, ResponseData> {
  return async (params) => {
    return await FBF.cloudcall(name, params);
  };
}

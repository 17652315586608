import { Field, useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import { InputRadioSettingsShape } from '@rabbit/elements/shared-types';

export interface InputRadioProps {
  name: string;
  settings: InputRadioSettingsShape; //any to remove
}

const dynamicStyles = {
  base: 'text-primary-900 focus:ring-transparent h-4 w-4 rounded border-gray-300 bg-gray-100',
  disabled: 'border-gray-300 text-gray-500 opacity-30',
};

export function InputRadio({ name, settings }: InputRadioProps) {
  const {
    radioValue,
    radioLabel,
    radioLabelStyles,
    onChange,
    values,
    ...rest
  } = settings;
  const [field] = useField(name);

  return (
    <label className="flex gap-2 leading-[1.2rem]">
      <Field
        {...field}
        {...rest}
        className={classNames(dynamicStyles.base, {
          [`${dynamicStyles.disabled}`]: rest.disabled,
          ['hidden']: rest.format === 'button',
        })}
        type="radio"
        name={name}
        value={radioValue}
      />
      {radioLabel && (
        <span
          className={classNames(radioLabel, {
            [`${dynamicStyles.disabled}`]: rest.disabled,
            'w-full cursor-pointer rounded-md border border-gray-200 bg-gray-50 p-3':
              rest.format === 'button',
            'bg-primary-200':
              rest.format === 'button' && values[name] === radioValue,
          })}
        >
          {radioLabel}
        </span>
      )}
    </label>
  );
}

export default InputRadio;

import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { Money, Z_Money } from '../base/basic';
import {
  RetailerLink,
  WarrantorLink,
  Z_RetailerLink,
  Z_WarrantorLink,
} from '../persona';
import { z, schema } from '@rabbit/utils/ts';
import {
  DTOptionsSingleOption,
  DeciderScheduleStringified,
  Z_DTOptionsSingleOption,
} from '../decider/types';

export type RecursiveNumberArray = Array<number | RecursiveNumberArray>;

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                Subset Types                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

export enum WarrantyTypes {
  Manufacturer = 'Manufacturer',
  ExtendedManufacturer = 'Extended Manufacturer',
  Retailer = 'Retailer',
}

/* -------------------------------------------------------------------------- */
/*                            Warranty information                            */
/* -------------------------------------------------------------------------- */

/** A single item of warranty information, such as a right, exclusion, or
 * coverage. This data is displayed on Olive's holding warranty info pages. */
export interface WarrantyInfoItem {
  /** The title of the item */
  title: string;

  /** The description of the item */
  description?: string;

  /** Is this item included in the warranty?  */
  included?: string;
}

/** Warranty info sections. */
export interface WarrantyInfoSection {
  /** The title of the section */
  title: string;

  /** The type of the section.
   * 'Checks' determines whether to display if the item is included in the warranty
   * 'Simple' is just plain text
   * */
  type?: 'checks' | 'simple';

  /** The items in the section */
  items: WarrantyInfoItem[];
}

export const Z_WarrantyInfoItem = z.object({
  title: z.string(),
  description: z.optional(z.string()),
  included: z.optional(z.string()),
});

export const Z_WarrantyInfoSection = z.object({
  title: z.string(),
  type: z.optional(z.enum(['checks', 'simple'])),
  items: z.array(Z_WarrantyInfoItem),
});

/* -------------------------------------------------------------------------- */
/*                                 Conditions                                 */
/* -------------------------------------------------------------------------- */
/** The conditions that must be met in order for a Warranty to be offered for a given vendable */
export interface WarrantyCondition {
  and?: WarrantyCondition[];
  or?: WarrantyCondition[];
  field: string;
  operator: '==' | '!=' | '<' | '<=' | '>' | '>='; // in future maybe: 'in' | 'not in', etc.
  value: WarrantyConditionValue;
}

export type WarrantyConditionValue =
  | string
  | number
  | boolean
  | DTOptionsSingleOption; // in future maybe | string[] | number[] | boolean[]

export const Z_WarrantyConditionValue = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  Z_DTOptionsSingleOption,
]);

export type WarrantyTemplateParameters = Record<string, WarrantyConditionValue>;

export const Z_WarrantyTemplateParameters = z.record(
  z.string(),
  Z_WarrantyConditionValue
);

/* -------------------------------------------------------------------------- */
/*                              Warranty Options                              */
/* -------------------------------------------------------------------------- */

//TL;DR: Warranty options are the different options a customer can choose from when buying a warranty.
// Their structure goes: WarrantyOptions -> array of options and their pricing matrix -> WarrantyOptionItems
/* ----------------------- Single warranty option item ---------------------- */

export type WarrantyOptionItem = {
  /** The name of the option item, to use in the UI.
   *  Eg. optionKey == duration, itemLabel = "12 months ",
   *  optionKey == claimLimit, itemLabel = "1000€ + VAT"  */
  itemLabel?: string;

  /** The value of the option item, to use in the UI.
   * For now we'll probably just use strings for everything, but in
   * the future we might want to use a more structured format, hence the any;
   *  Eg. optionKey == duration, value = "P12M",
   *  optionKey == claimLimit, value = "1000€ + VAT"  */
  itemValue: any;
};

export const Z_WarrantyOptionItem = z.object({
  itemLabel: z.optional(z.string()),
  itemValue: z.any(),
});

/* ------------------------- Single warranty option ------------------------- */
export type WarrantyOption = {
  /**
   * The key of the option, used to identify the option in the system.
   *
   * Note: if the optionKey is 'duration', it should use ISO 8601 duration format
   * more: https://www.digi.com/resources/documentation/digidocs//90001488-13/reference/r_iso_8601_duration_format.htm
   */
  optionKey: string;

  /** The name of the option, used in the UI */
  optionName?: string;

  /** An array of items (or sub-options) for the option, including the price for each */
  optionItems: WarrantyOptionItem[];
};

export const Z_WarrantyOption = z.object({
  optionKey: z.string(),
  optionName: z.optional(z.string()),
  optionItems: z.array(Z_WarrantyOptionItem),
});

/* ---------------------------- Warranty Options ---------------------------- */
export type WarrantyOptions = {
  optionList: WarrantyOption[];
  pricingCurrency: string;
  // a stringified RecursiveNumberArray, aka a multi-dimensional array of numbers
  pricing: string;
};

export const Z_WarrantyOptions = z.object({
  optionList: z.array(Z_WarrantyOption),
  pricingCurrency: z.optional(z.string()),
  pricing: z.optional(z.string()),
});
/* -------------------------------------------------------------------------- */
/*                              Warranty Template                             */
/* -------------------------------------------------------------------------- */

/** A warranty product offered by a Warrantor, usually a retailer or manufacturer. */
export interface DTWarranty_Template extends NoSqlDoc {
  /** The order in which the warranty template is displayed on UI */
  orderNo: number;

  /** The entity that provides this warranty template and handles claims related to it */
  warrantorLink: WarrantorLink;

  /** The name of the warranty template */
  title: string;

  /** @deprecated use *options*  instead */
  durations?: string[];

  /** the options and their price */
  options: WarrantyOptions;

  /** @deprecated pricing is now in options  instead */
  /** Price of the warranty, if applicable */
  price?: Money;

  /** Array of retailers authorised to sell this warranty */
  retailers?: RetailerLink[];

  /** The warranty information, displayed on Olive */
  info?: WarrantyInfoSection[];

  /** The warranty terms, displayed on Olive */
  terms?: WarrantyInfoSection[];

  /** The conditions that must be met in order for a Warranty to be offered for a given vendable
   *  If empty, the warranty is offered for all vendables.
   */
  conditions?: WarrantyCondition;

  /** The type of the warranty template. Defaults to manufacturer */
  type: WarrantyTypes;

  /** If decisions can be made with this template, the schedule defines them */
  decider?: DeciderScheduleStringified;
}

export const FBD_Warranty_Template = MakeFBDocType<DTWarranty_Template>({
  name: 'Warranty Template',
  collection: 'warranty_template',
  empty: () => {
    const result: DTWarranty_Template = {
      ...Empty_NoSqlDoc(),
      orderNo: 0,
      title: '',
      warrantorLink: '',
      options: {} as WarrantyOptions,
      type: WarrantyTypes.Manufacturer,
    };
    return result;
  },
  keygen: FBDTKeygenGeneric, // DC: A WarrantorLink + id or a short title?
});

export const Z_DTWarranty_Template = schema<DTWarranty_Template>()(
  z
    .object({
      warrantorLink: Z_WarrantorLink,
      title: z.string(),
      durations: z.array(z.string()).or(z.undefined()),
      type: z.nativeEnum(WarrantyTypes),
      price: z.optional(Z_Money),
      retailers: z.optional(z.array(Z_RetailerLink)),
      info: z.optional(z.array(Z_WarrantyInfoSection)),
      terms: z.optional(z.array(Z_WarrantyInfoSection)),
      orderNo: z.number(),
      options: Z_WarrantyOptions,
      decider: z.string().optional(),
    })
    .merge(Z_NoSqlDoc)
);

export type WarrantyTemplateLink = string;

export const Z_WarrantyTemplateLink = z.string();

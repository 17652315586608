import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { Z_VehicleInfo } from '@rabbit/data/types';
import { ICF_Base } from './base';

/** Get VechicleInfo */

const ICF_GetVechicleInfo = z.object({
  registrationNo: z.string(),
  tenant: z.string(),
}).merge(ICF_Base);

export const OCF_GetVechicleInfo = Z_VehicleInfo;

export const CFC_GetVechicleInfo = MakeCloudFunctionSpec(
  'cfcgetvehicleinfo',
  ICF_GetVechicleInfo,
  OCF_GetVechicleInfo
);

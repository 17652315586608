import { Z_Money, Z_UserUploadedDocument } from '@rabbit/data/types';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from '../base';

const Z_SheltaHoldingRegistrationFormData = z.object({
  purchase_time: z.number(),
  purchase_price: Z_Money.optional(),
  purchase_location: z.nullable(z.string()).optional(),
  purchase_location_other: z.nullable(z.string()).optional(),
  purchase_country: z.string().optional(),
  serial: z.string().optional(),
  receipt: z.array(Z_UserUploadedDocument).optional(),
});

const ICF_RegisterSheltaHolding = z
  .object({
    vendableDocid: z.string(),
    formData: Z_SheltaHoldingRegistrationFormData,
  })
  .merge(ICF_Base);

const OCF_RegisterSheltaHolding = z.string();

export const CFC_RegisterSheltaHolding = MakeCloudFunctionSpec(
  'cfcregistersheltaholding',
  ICF_RegisterSheltaHolding,
  OCF_RegisterSheltaHolding
);

import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { Z_DTBusiness_User_Invite } from '@rabbit/data/types';
import { ICF_Base } from '../base';

/* -------------------------------------------------------------------------- */
/*                            Business User Invite                            */
/* -------------------------------------------------------------------------- */

/* ---------------------------- Generate document --------------------------- */

export const ICF_GenerateBusinessUserInvite = z.object({
  inviteData: Z_DTBusiness_User_Invite.partial(),
  baseUrl: z.string(),
});
// export const ICF_GenerateBusinessUserInvite = z
//   .object({
//     inviteData: Z_DTBusiness_User_Invite.partial(),
//     baseUrl: z.string(),
//   })
//   .merge(ICF_Base);

export const OCF_GenerateBusinessUserInvite = z.object({
  doc: Z_DTBusiness_User_Invite,
  link: z.string(),
});

/** Generates a Business User Invite document and returns the generated document and the invite link */
export const CFC_GenerateBusinessUserInvite = MakeCloudFunctionSpec(
  'cfcgeneratebusinessuserinvite',
  ICF_GenerateBusinessUserInvite,
  OCF_GenerateBusinessUserInvite
);

/* ------------------------------ Resend invite ----------------------------- */
export const ICF_ResendBusinessUserInvite = z.object({
  docid: z.string(),
  baseUrl: z.string(),
  emailParams: z.object({
    from: z.string(),
    business_name: z.string(),
    business_logo: z.string(),
    main_template: z.string(),
    partner_type: z.string(),
  }),
}).merge(ICF_Base);

export const OCF_ResendBusinessUserInvite = z.object({
  doc: Z_DTBusiness_User_Invite,
  link: z.string(),
});

/** Generates a Business User Invite document and returns the generated document and the invite link */
export const CFC_ResendBusinessUserInvite = MakeCloudFunctionSpec(
  'cfcresendbusinessuserinvite',
  ICF_ResendBusinessUserInvite,
  OCF_ResendBusinessUserInvite
);
/* ------------------------------ Generate link ----------------------------- */
// Disabled for now as it's not currently used, but could be in the future - DC
// e.g a "regenerate invite link" button

// export const ICF_GenerateBusinessUserInviteLink = z.object({
//   docid: z.string(),
//   baseUrl: z.string(),
// });

// export const OCF_GenerateBusinessUserInviteLink = z.object({
//   link: z.string(),
// });

// /** Generates an invite link for a given Business_User_Invite document */
// export const CFC_GenerateBusinessUserInviteLink = MakeCloudFunctionSpec(
//   'cfcgeneratebusinessuserinvitelink',
//   ICF_GenerateBusinessUserInviteLink,
//   OCF_GenerateBusinessUserInviteLink
// );

/* ------------------------------- Decode link ------------------------------ */
export const ICF_DecodeBusinessUserInviteLink = z.object({
  c: z.string(),
  iv: z.string(),
});

export const OCF_DecodeBusinessUserInviteLink = z.object({
  inviteData: Z_DTBusiness_User_Invite.partial(),
});

export const CFC_DecodeBusinessUserInviteLink = MakeCloudFunctionSpec(
  'cfcdecodebusinessuserinvitelink',
  ICF_DecodeBusinessUserInviteLink,
  OCF_DecodeBusinessUserInviteLink
);

/* -------------------------- Business User Signup -------------------------- */
export const ICF_BusinessUserSignup = z.object({
  inviteData: Z_DTBusiness_User_Invite.partial(),
  password: z.string(),
  premiumTenant: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export const OCF_BusinessUserSignup = z.object({
  success: z.boolean(),
});

export const CFC_BusinessUserSignup = MakeCloudFunctionSpec(
  'cfcbusinessusersignup',
  ICF_BusinessUserSignup,
  OCF_BusinessUserSignup
);

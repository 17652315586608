import {
  Address,
  TenantLink,
  UserUploadedDocument,
  Z_Address,
  Z_UserUploadedDocument,
} from '@rabbit/data/types';
import { z } from 'zod';

export enum Caseflow_ActorTypes {
  CONSUMER = 'consumer',
  REPAIRER = 'repairer',
  WARRANTOR = 'warrantor',
}

/* -------------------------------------------------------------------------- */
/*                         Form data for case creation                        */
/* -------------------------------------------------------------------------- */
export interface Caseflow_CaseCreation_Sage_Shelta_FormDataShape {
  consumer_first_name: string;
  consumer_last_name: string;
  consumer_address: Address;
  consumer_email: string;
  consumer_telephone: string;
  consumer_preferred_contact: Array<'phone' | 'email'>;
  purchase_date: number;
  consumer_issue_type: string;
  consumer_issue_type_ref: string;
  consumer_issue_description: string;
  consumer_holding_purchase_location: string;
  consumer_holding_purchase_location_other?: string | null;
  consumer_proof_of_purchase: UserUploadedDocument[];
  consumer_claim_evidence: UserUploadedDocument[];
  holding_vendable_id: string;
  internal_comment: string;
}

export const Z_Caseflow_CaseCreation_Sage_Shelta_FormDataShape = z.object({
  consumer_address: Z_Address.partial(),
  consumer_email: z.string(),
  consumer_first_name: z.string(),
  consumer_last_name: z.string(),
  consumer_telephone: z.string(),
  consumer_preferred_contact: z.array(
    z.union([z.literal('phone'), z.literal('email')])
  ),
  purchase_date: z.number(),
  consumer_issue_type: z.string(),
  consumer_issue_type_ref: z.string(),
  consumer_issue_description: z.string(),
  consumer_holding_purchase_location: z.string(),
  consumer_holding_purchase_location_other: z.string().nullable(),
  consumer_proof_of_purchase: z.array(Z_UserUploadedDocument),
  consumer_claim_evidence: z.array(Z_UserUploadedDocument),
  holding_vendable_id: z.string(),
  internal_comment: z.string(),
});
export interface Caseflow_CaseCreation_Sage_Lite_FormDataShape {
  consumer_issue_type: string;
  consumer_issue_type_ref: string;
  consumer_issue_description?: string;
  internal_comment?: string;
}

export const Z_Caseflow_CaseCreation_Sage_Lite_FormDataShape = z.object({
  consumer_issue_type: z.string(),
  consumer_issue_type_ref: z.string(),
  consumer_issue_description: z.string().optional(),
  internal_comment: z.string().optional(),
});

export interface Caseflow_CaseCreation_Olive_FormDataShape {
  consumer_issue_type: string;
  consumer_issue_type_ref: string;
  consumer_proof_of_purchase: UserUploadedDocument[];
  consumer_issue_description: string;
  consumer_claim_evidence: UserUploadedDocument[];
}

export const Z_Caseflow_CaseCreation_Olive_FormDataShape = z.object({
  consumer_issue_type: z.string(),
  consumer_issue_type_ref: z.string(),
  consumer_proof_of_purchase: z.array(Z_UserUploadedDocument),
  consumer_issue_description: z.string(),
  consumer_claim_evidence: z.array(Z_UserUploadedDocument),
});

/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */

export interface CFCF_InternalComment {
  comment: string;
  author: string;
}

export enum CFCF_FileUpdateEventAction {
  UPLOAD = 'upload',
  DELETION = 'deletion',
}

export enum CFCF_FileUpdateEventCategory {
  PROOF_OF_PURCHASE = 'consumer_proof_of_purchase',
  CLAIM_EVIDENCE = 'consumer_claim_evidence',
  SHIPPING_LABEL = 'item_shipping_label',
}

export interface CFCF_FileUpdateEvent {
  file: UserUploadedDocument;
  action: CFCF_FileUpdateEventAction;
  category: CFCF_FileUpdateEventCategory;
}

export interface CFCF_PartnerTenantData {
  partner_tenant_id: TenantLink;
  partner_tenant_label: string;
  partner_tenant_name?: string;
  partner_tenant_address?: Address;
  partner_tenant_email?: string;
}

import { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import { handleKeyPress } from '@rabbit/elements/shared-components';
import { SearchRetailers } from '@rabbit/search/cherchons';
import { InputTypeSelectSettingsShape } from '@rabbit/elements/shared-types';
import { DTRetailer_Public } from '@rabbit/data/types';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { generateSelectStyles } from '../../../utils/consts';

export interface InputAutoCompleteLocationRetailersProps {
  name: string;
  settings: InputTypeSelectSettingsShape;
  onChange?: (data: any) => void;
}

export function InputAutoCompleteLocationRetailers({
  name,
  settings,
  onChange,
}: InputAutoCompleteLocationRetailersProps) {
  const { setFieldValue } = useFormikContext();
  const {
    placeholder,
    isMulti = false,
    isClearable = true,
    disabled = false,
    ...props
  } = settings;
  const [field, meta] = useField(name);

  const [options, setOptions] = useState([]);
  const [inputText, setInputText] = useState('');
  const debouncedInput = useDebounce(inputText, 500);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  let errorField = false;
  if (meta?.error) errorField = true;

  async function onChangeDefault(option: any) {
    if (isMulti) {
      await setFieldValue(
        name,
        option ? option.map((item: { value: any }) => item.value) : []
      );
    } else {
      await setFieldValue(name, option?.value ? option.value : '');
    }
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: { value: any }) => field.value.indexOf(option.value) >= 0
          )
        : options.find(
            (option: { value: any }) => option.value === field.value
          );
    } else {
      return isMulti ? [] : '';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await SearchRetailers(inputText, {
        hitsPerPage: 500,
        page: 0,
      });
      if (data?.items.length) {
        const newOptions: any[] = [];
        data.items.map((item: DTRetailer_Public) => {
          newOptions.push({
            id: item.docid,
            label:
              item.name +
              ' (' +
              (item?.address?.town ?? '') +
              ' - ' +
              (item?.address?.county ?? '') +
              ')',
            value: {
              docid: item.docid,
              country: item?.address?.country,
            },
          });
        });
        setOptions(newOptions as any);
      }
      setIsLoadingResults(false);
    };

    if (debouncedInput.length) {
      setIsLoadingResults(true);
      void fetchData();
    } else setOptions([]);
  }, [debouncedInput]);

  const onInputChange = (e: string) => {
    setInputText(e);
  };

  return (
    <Select
      {...field}
      {...props}
      options={options}
      isMulti={isMulti}
      classNames={generateSelectStyles(errorField)}
      //styles={errorField ? errorCustomStyles : customStyles}
      placeholder={placeholder}
      defaultValue={field.value}
      components={
        settings.dropDown === 'disabled'
          ? {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
          : undefined
      }
      value={
        options
          ? isMulti
            ? options.find(
                (option: { value: any }) => option.value === field.value
              )
            : getValue()
          : ''
      }
      // Doesn't seem to be doing anything, uncomment if necessary - dc
      // error
      onKeyDown={handleKeyPress}
      onChange={onChange ?? onChangeDefault}
      onInputChange={onInputChange}
      isClearable={isClearable}
      isDisabled={disabled}
      noOptionsMessage={({ inputValue }) =>
        !inputValue
          ? 'Type something...'
          : inputText === debouncedInput && !isLoadingResults
          ? 'No results found'
          : 'Loading...'
      }
    />
  );
}

export default InputAutoCompleteLocationRetailers;

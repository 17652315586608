import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';
import { SetFirestore } from './implementation';

export function StartFirebasePure(params: {
  firebaseMode: string;
  cloudRegion: string;
  emulateAuth?: boolean;
}) {
  // ================================================================================================
  if (params.firebaseMode === 'EMULATOR') {
    console.log('Firebase: Talk to Emulator');

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: 'AIzaSyAmTS6Wiiv-fnrDUaQv4c8AyOk5KN9a_c0',
      authDomain: 'firerabbit-8e682.firebaseapp.com',
      projectId: 'firerabbit-8e682',
      storageBucket: 'firerabbit-8e682.appspot.com',
      messagingSenderId: '148047598597',
      appId: '1:148047598597:web:0fb6599bc4fe2c6c41b86c',
      measurementId: 'G-GM459GNR0G',
      // appId: '1:148047598597:web:e08ab41537080d9a41b86c',
      // measurementId: 'G-YE1Z2XTST3', // TODO: Vary this based on olive or sage if we are using analytics
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = undefined; // not supported in emulator environment
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    // FIREBASE EMULATOR START ================
    connectFirestoreEmulator(db, document.location.hostname, 8080);
    connectFunctionsEmulator(functions, document.location.hostname, 5001);
    if (params.emulateAuth) {
      connectAuthEmulator(auth, `http://${document.location.hostname}:9099`);
    }
    // FIREBASE EMULATOR END ================

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };

    // ================================================================================================
  } else if (params.firebaseMode === 'WRECK') {
    console.log('Firebase: WRECK development');

    const firebaseConfig = {
      apiKey: 'AIzaSyAmTS6Wiiv-fnrDUaQv4c8AyOk5KN9a_c0',
      authDomain: 'firerabbit-8e682.firebaseapp.com',
      projectId: 'firerabbit-8e682',
      storageBucket: 'firerabbit-8e682.appspot.com',
      messagingSenderId: '148047598597',
      appId: '1:148047598597:web:0fb6599bc4fe2c6c41b86c',
      measurementId: 'G-GM459GNR0G', // TODO: Vary this based on olive or sage if we are using analytics
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };

    // ================================================================================================
  } else if (params.firebaseMode === 'HOT') {
    console.log('Firebase: HOT deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyCWOi6kU93q9N4gm23z825-Y15Rg7YywQc',
      authDomain: 'hot-firerabbit.firebaseapp.com',
      projectId: 'hot-firerabbit',
      storageBucket: 'hot-firerabbit.appspot.com',
      messagingSenderId: '90262375576',
      appId: '1:90262375576:web:f3944782a69a276d8354ed',
      measurementId: 'G-H8ZRLY9V5C', // TODO: Vary this based on olive or sage if we are using analytics
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };

    // ================================================================================================
  } else if (params.firebaseMode === 'LAUNCHPAD') {
    console.log('Firebase: LAUNCHPAD deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyA9I6Vg7AW9sZovXJXAWFe3s-tnYOr2JR4',
      authDomain: 'launchpad-firerabbit.firebaseapp.com',
      projectId: 'launchpad-firerabbit',
      storageBucket: 'launchpad-firerabbit.appspot.com',
      messagingSenderId: '758099175511',
      appId: '1:758099175511:web:fb2981e60e9c11ac3c1b12',
      measurementId: 'G-577ZKS4TQN',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions }; // ================================================================================================
  } else if (params.firebaseMode === 'MOTO') {
    console.log('Firebase: MOTO deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyA3xxOuhLgSMzksE0rAf7YOlnePRBk4ig8',
      authDomain: 'moto-firerabbit.firebaseapp.com',
      projectId: 'moto-firerabbit',
      storageBucket: 'moto-firerabbit.appspot.com',
      messagingSenderId: '395379235406',
      appId: '1:395379235406:web:cbb996066f2cb2cb6dc11f',
      measurementId: 'G-KZ596H438H',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions }; // ================================================================================================
    // ================================================================================================
  } else if (params.firebaseMode === 'LIVE') {
    console.log('Firebase: LIVE deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyCmk0W77zNaNwHWEaaMfSlywAScevwhycw',
      authDomain: 'live-firerabbit.firebaseapp.com',
      projectId: 'live-firerabbit',
      storageBucket: 'live-firerabbit.appspot.com',
      messagingSenderId: '586717431289',
      appId: '1:586717431289:web:605cefdff3307342c9e928',
      measurementId: 'G-77E1JNZZMC',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions }; // ================================================================================================
  } else if (params.firebaseMode === 'EULIVE') {
    console.log('Firebase: LIVE deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyCep5NcPL9r4quDLg9e4dnICWFx9l_krQU',
      authDomain: 'eulive-78764.firebaseapp.com',
      projectId: 'eulive-78764',
      storageBucket: 'eulive-78764.appspot.com',
      messagingSenderId: '401897698678',
      appId: '1:401897698678:web:80990384d6d7fea098f657',
      measurementId: 'G-ESMWDB4XEZ',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };
  } else if (params.firebaseMode === 'EULAUNCHPAD') {
    console.log('Firebase: EU-LAUNCHPAD deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyBk9jWT-Xp0GTAD4TRBKo6RcxeDjvZRGVU',
      authDomain: 'eulaunchpad-firerabbit.firebaseapp.com',
      projectId: 'eulaunchpad-firerabbit',
      storageBucket: 'eulaunchpad-firerabbit.appspot.com',
      messagingSenderId: '165985834709',
      appId: '1:165985834709:web:3af86b78b43f93550cf45c',
      measurementId: 'G-HJB4MX709G',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };
  } else if (params.firebaseMode === 'EUSANDBOX') {
    console.log('Firebase: EU-SANDBOX deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyCcYBj9e5Ct6fcVxcBsf_9b_4DoOPSWhZc',
      authDomain: 'eusandbox-firerabbit.firebaseapp.com',
      projectId: 'eusandbox-firerabbit',
      storageBucket: 'eusandbox-firerabbit.appspot.com',
      messagingSenderId: '446376034519',
      appId: '1:446376034519:web:d22a9f6e91aa5ff6fef092',
      measurementId: 'G-NJZKTSDMYS',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };
  } else if (params.firebaseMode === 'DEMO') {
    console.log('Firebase: DEMO deploy');

    const firebaseConfig = {
      apiKey: 'AIzaSyBFYW8-AXloEs3B_qHOdMzZmk_EVBplsxQ',
      authDomain: 'fir-firerabbit.firebaseapp.com',
      projectId: 'fir-firerabbit',
      storageBucket: 'fir-firerabbit.appspot.com',
      messagingSenderId: '713416164954',
      appId: '1:713416164954:web:a7b8f618620e5f8ca2fa63',
      measurementId: 'G-8ZNTFYSXKT',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    const auth = getAuth(app);
    const storage = getStorage(app);
    const functions = getFunctions(app, params.cloudRegion);

    SetFirestore(db, functions);

    return { app, analytics, db, auth, storage, functions };
  } else {
    throw new Error(
      'libs/firebase/react: This build does not specify which firebase to connect to. Make sure to set VITE_FIREBASE_MODE environment variable.'
    );
  }
}

import { Warranty, WarrantyStatus } from '@rabbit/data/types';

export interface BLIWarranty {
  getLatestWarranty: (
    warranties: Warranty[] | null | undefined
  ) => Warranty | undefined;
}

export const BL_Warranty: BLIWarranty = {
  getLatestWarranty,
};

function getLatestWarranty(
  warranties: Warranty[] | null | undefined
): Warranty | undefined {
  if (!warranties || warranties.length === 0) return undefined;

  const activeWarranties = warranties.filter(
    (warranty) => warranty.status === WarrantyStatus.ACTIVE
  );

  if (activeWarranties.length === 0) {
    const sortedWarranties = warranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedWarranties[0];
  } else if (activeWarranties.length > 1) {
    const sortedWarranties = activeWarranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedWarranties[0];
  }

  return activeWarranties[0];
}

import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';

/* -------------------------------------------------------------------------- */
/*                                 Categories                                 */
/* -------------------------------------------------------------------------- */

 /** Unique ID, lowercase, a-z0-9\- used for deduplication */
export type DTCategoryKey = string;

/** Collection of keys from all levels to be stored inside a doc to aid search indexing */
export type DTCategoryInfo = DTCategoryKey[];

/** Category node for vendable - note categories are part of a tree hierarchy */
export interface DTCategory_Public extends NoSqlDoc {

  /** Unique key for category **/
  key: DTCategoryKey;

  /** level - depth of lineage, useful for scoring in search etc */
  level: number;

  /** Collection of keys from all levels to be stored inside a doc to aid search indexing */
  lineage: DTCategoryInfo;

  /** Human readable title. English (en) must exist, other languages are optional */
  titles: {
    en: string;
    [lang: string]: string;
  }

  /** Search phrase - Language specific search phrase using whole lineage */
  // TODO - use reverse mode?
  search?: {
    en: string;
    [lang: string]: string;
  }
}

/** Category node for vendable - note categories are part of a tree hierarchy */
export interface DTCategory_Private extends NoSqlDoc {
  /** Unique key for category **/
  key: DTCategoryKey;

  //amortisationPeriod: number; // years - TODO - flesh out and model use cases
  //depreciationRate: number; // percentage / year - TODO - flesh out and model use cases
}

export const FBD_Category_Public = MakeFBDocType<DTCategory_Public>({
  name: 'Category_Public',
  collection: 'category_public',
  empty: () => {
    const result: DTCategory_Public = {
      ...Empty_NoSqlDoc(),
      key: '',
      level: -1,
      lineage: [],
      titles: {
        en: '',
      },
      search: {
        en: '',
      },
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

export const FBD_Category_Private = MakeFBDocType<DTCategory_Private>({
  name: 'Category_Private',
  collection: 'category_private',
  empty: () => {
    const result: DTCategory_Private = {
      ...Empty_NoSqlDoc(),
      key: '',
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

import { GreaseCodex, GreaseRule } from '@rabbit/grease/elements';
import { MixMasterAlterRecipe } from '@rabbit/mixmaster/react';

type RuleMatchState = 'fresh' | 'matched' | 'stalled' | 'none';
let matchState: RuleMatchState = 'fresh';
let domain = '';
let codex: GreaseCodex = [];

type RuleMatchResult = 'matched' | 'stalled' | 'miss';

function DoesRuleMatch(rule: GreaseRule): RuleMatchResult {
  if (rule.domain) {
    // One day you can add regex here if you feel like it
    if (domain !== rule.domain) {
      return 'miss';
    }
  }
  return 'matched';
}

function ExecuteRule(rule: GreaseRule) {
  if (rule.manufacturer) {
    MixMasterAlterRecipe('manufacturer', rule.manufacturer);
  }
}

function CheckAllRules() {
  for (const rule of codex) {
    // check for a match first
    const outcome = DoesRuleMatch(rule);
    if (outcome === 'miss') {
      continue;
    }
    if (outcome === 'stalled') {
      console.log('Grease: stalled on rule', rule);
      matchState = 'stalled';
      return;
    }

    console.log('Grease: Executing rule', rule);
    ExecuteRule(rule);
    matchState = 'matched';
    return;
  }
  matchState = 'none';
  console.log("Grease: didn't match any rules");
}

function GetCurrentDomain() {
  // Get the current domain
  let domain = window.location.hostname;

  // If there is a query string "GDOMAIN=", use that as the domain
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryDomain = urlParams.get('GDOMAIN');
  if (queryDomain) {
    console.log(`GreaseGateway: Using query domain: ${queryDomain}`);
    domain = queryDomain;
  }
  domain = domain.toLowerCase();

  console.log('Grease: domain to match:', domain);
  return domain;
}

export function SetupGreaseGateway(aCodex: GreaseCodex) {
  if (matchState !== 'fresh') {
    throw new Error('GreaseGateway: Already setup');
  }
  codex = aCodex;
  domain = GetCurrentDomain();
  CheckAllRules();
}

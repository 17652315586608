import { CaseFlowConfiguration } from '@rabbit/data/types';

/* ---------------------------------- Links --------------------------------- */
export const CaseFlowConfig_Facts_DocLinks: CaseFlowConfiguration['facts'] = {
  consumer_holding: {
    type: 'DocLink', //HoldingLink?
    label: 'Product with issue',
  },
  consumer_persona_id: {
    type: 'ConsumerLink',
    label: 'Consumer persona',
  },
  holding_vendable_id: {
    type: 'DocLink', // VendableLink?
    label: 'Vendable ID for consumer holding',
  },
  warranty_link: {
    type: 'DocLink',
    label: 'Warranty ID for consumer holding',
  },
};

/* -------------------------------- Comments -------------------------------- */
export const CaseFlowConfig_Facts_Comments: CaseFlowConfiguration['facts'] = {
  internal_comment: {
    type: 'CFCF_InternalComment',
    label: 'Internal comment',
  },
  comment_to_customer: {
    type: 'String',
    label: 'Comment to customer',
  },
  internal_case_files: {
    type: 'InternalCaseFile[]', // todo document
    label: 'Internal case files',
  },
};

/* ------------------------------ Issue details ----------------------------- */
export const CaseFlowConfig_Facts_IssueDetails: CaseFlowConfiguration['facts'] =
  {
    consumer_issue_type: {
      type: 'String', // TODO: have as enum of 'defect' | water | accidental | other
      label: 'Issue type',
    },
    consumer_issue_type_ref: {
      type: 'String',
      label: 'Issue type reference',
    },
    consumer_issue_description: {
      type: 'String',
      label: 'Issue description',
    },
    // TODO: name isn't clear enough here. Maybe change to faults_identified one day
    holding_faults: {
      type: 'string[]',
      label: 'Faults identified on holding',
    },
  };

/* --------------------------- Uploaded file data --------------------------- */
export const CaseFlowConfig_Facts_UploadedFiles: CaseFlowConfiguration['facts'] =
  {
    /* ------------------------- Main file repositories ------------------------- */
    // These have a list of all the files of a given type uploaded for a case
    consumer_claim_evidence: {
      type: 'UserUploadedDocument',
      label: 'Claim evidence',
    },
    consumer_proof_of_purchase: {
      type: 'UserUploadedDocument',
      label: 'Proof of purchase',
    },
    /* --------------------------- File update events --------------------------- */
    // Whenever we upload or delete one or more files, we should record it here so the activity log can display it properly
    file_update_event: {
      type: 'CFCF_FileUpdateEvent[]',
      label: 'File update event',
    },
  };

/* ------------------------------ Delegate info ----------------------------- */
export const CaseFlowConfig_Facts_Delegates: CaseFlowConfiguration['facts'] = {
  delegate_repairer_name: {
    type: 'String',
    label: 'Delegate repairer id',
  },
  delegate_repairer_id: {
    type: 'String',
    label: 'Delegate repairer id',
  },
};

/* ------------------------------ Holding data ------------------------------ */
export const CaseFlowConfig_Facts_Holding: CaseFlowConfiguration['facts'] = {
  vendable_version_number: {
    type: 'Number',
    label: 'Vendable version number',
  },
  consumer_holding_category: {
    type: 'String',
    label: 'Holding category',
  },
  consumer_holding_name: {
    type: 'String',
    label: 'Product name',
  },
  consumer_holding_image: {
    type: 'String',
    label: 'Holding image url',
  },
  consumer_holding_serial_number: {
    type: 'String',
    label: 'Serial number',
  },
  consumer_holding_purchase_country: {
    type: 'String',
    label: 'Purchase country',
  },
  purchase_date: {
    type: 'Date',
    label: 'Date of product purchase',
  },
  consumer_holding_purchase_location: {
    type: 'RetailerLink',
    label: 'Purchase location',
  },
  consumer_holding_purchase_location_other: {
    type: 'String', // TODO: Define type - is it a retailer reference???
    label: 'Purchase location other',
  },
  consumer_holding_purchase_price: {
    type: 'Money',
    label: 'Purchase price',
  },
  holding_warranty_term: {
    type: 'WarrantyDuration',
    label: 'Length of holding warranty',
  },
};

/* ------------------------------ Consumer data ----------------------------- */
export const CaseFlowConfig_Facts_Consumer: CaseFlowConfiguration['facts'] = {
  consumer_name: {
    type: 'String',
    label: 'Customer name',
  },

  consumer_email: {
    type: 'String',
    label: 'Customer email address',
  },

  consumer_telephone: {
    type: 'String',
    label: 'Customer telephone number',
  },
  consumer_preferred_contact: {
    type: 'String', // TODO: ENUM TYPE?
    label: 'Customer preferred contact method',
  },
  outbound_address: {
    type: 'Address',
    label: 'Consumer address',
  },
};

export const CaseFlowConfig_Facts_CaseStatus: CaseFlowConfiguration['facts'] = {
  preliminary_assessment: {
    type: 'String',
    label: 'Preliminary warranty assessment',
  },

  final_assessment: {
    type: 'String',
    label: 'Final warranty assessment',
  },

  claim_outcome: {
    type: 'String',
    label: 'Claim outcome',
  },

  goodwill_override_initial: {
    type: 'Boolean',
    label: 'Goodwill override',
  },

  goodwill_warranty_initial: {
    type: 'String',
    label: 'Goodwill warranty',
  },

  goodwill_override_final: {
    type: 'Boolean',
    label: 'Goodwill override',
  },

  goodwill_warranty_final: {
    type: 'String',
    label: 'Goodwill warranty',
  },

  consumer_marked_item_sent: {
    type: 'Boolean',
    label: 'Consumer marked item as sent',
  },
};

/* ------------------------------ Partner data ------------------------------ */
export const CaseFlowConfig_Facts_PartnerData: CaseFlowConfiguration['facts'] =
  {
    consumer_holding_purchased_from_partner: {
      type: 'CFCF_PartnerTenantData',
      label: 'Partner the holding was purchased from',
    },
  };

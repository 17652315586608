import { ComponentType } from 'react';
import classNames from 'classnames';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

// TODO: Convert all icons to icomoon
export interface ButtonIconVerticalProps {
  kind: 'simple' | 'claim-actions' | 'product-categories' | 'navbar';
  label: string;
  //Todo: map icon props shape
  Icon: ComponentType<any>;
  onClick?: () => any;
  hintAction?: () => any;
  isActive?: boolean;
  disabled?: boolean;
}

export function ButtonIconVertical({
  kind,
  label,
  Icon,
  onClick,
  hintAction,
  isActive,
  disabled = false,
}: ButtonIconVerticalProps) {
  const dynamicStyles = {
    // Container
    containerBase:
      'flex flex-col relative justify-center cursor-pointer items-center',
    containerSimple: 'h-[80px] w-[63px]',
    containerCardGrey:
      'bg-gray-300 h-[110px] min-h-[110px] w-[110px] min-w-[110px] rounded-lg',
    containerCardGreyActive:
      'bg-gray-100 h-[110px] min-h-[110px] w-[110px] min-w-[110px] rounded-lg',
    containerCardGreen:
      'bg-primary-900 hover:bg-primary-800 h-[120px] min-h-[120px] w-[120px] min-w-[120px] rounded-xl',
    containerNavbar: 'xs:m-0 flex flex-col justify-center items-center',
    // Icon
    iconBase: 'mx-auto',
    iconSimple: 'w-7 text-black',
    iconCardGrey: 'w-11 h-8 text-gray-500',
    iconCardGreen: 'w-11 h-8 text-white',
    iconNavbar: 'md:hidden w-[20px] opacity-30 text-black',
    iconNavbarActive: 'md:hidden w-[20px] h-[20px] text-primary-900',
    // Label
    labelBase: 'font-nunito text-center',
    labelSimple: 'text-xs text-black mt-3',
    labelCardGrey: 'text-sm text-black mt-3',
    labelCardGreen: 'text-sm text-white mt-[15px] px-[10px]',
    labelNavbar:
      'text-sm md:text-2xl md:font-caudex md:font-bold opacity-30 text-black',
    labelNavbarActive:
      'text-sm md:text-2xl md:font-caudex md:font-bold text-primary-900',
    labelDisabled: 'text-gray-500 !cursor-default',
    disabled: 'text-gray-500 !cursor-default fill-gray-400 opacity-50',
    hintBase: 'absolute bottom-full -top-1 -right-2 w-6 h-6 cursor-pointer',
  };

  return (
    <button
      className={classNames(`${dynamicStyles.containerBase}`, {
        [`${dynamicStyles.containerSimple}`]: kind === 'simple',
        [`${dynamicStyles.containerCardGrey}`]:
          kind === 'product-categories' && !isActive,
        [`${dynamicStyles.containerCardGreyActive}`]:
          kind === 'product-categories' && isActive,
        [`${dynamicStyles.containerCardGreen}`]: kind === 'claim-actions',
        [`${dynamicStyles.containerNavbar}`]: kind === 'navbar',
      })}
      onClick={disabled ? () => 0 : onClick}
    >
      <Icon
        className={classNames(`${dynamicStyles.iconBase}`, {
          [`${dynamicStyles.iconSimple}`]: kind === 'simple',
          [`${dynamicStyles.iconCardGrey}`]: kind === 'product-categories',
          [`${dynamicStyles.iconCardGreen}`]: kind === 'claim-actions',
          [`${dynamicStyles.iconNavbar}`]: kind === 'navbar' && !isActive,
          [`${dynamicStyles.iconNavbarActive}`]: kind === 'navbar' && isActive,
          [`${dynamicStyles.disabled}`]: disabled,
        })}
      />
      {hintAction && (
        <InformationCircleIcon
          onClick={hintAction}
          className={dynamicStyles.hintBase}
        />
      )}
      <p
        className={classNames(`${dynamicStyles.labelBase}`, {
          [`${dynamicStyles.labelSimple}`]: kind === 'simple',
          [`${dynamicStyles.labelCardGrey}`]: kind === 'product-categories',
          [`${dynamicStyles.labelCardGreen}`]: kind === 'claim-actions',
          [`${dynamicStyles.labelNavbar}`]: kind === 'navbar' && !isActive,
          [`${dynamicStyles.labelNavbarActive}`]: kind === 'navbar' && isActive,
          [`${dynamicStyles.labelDisabled}`]: disabled,
        })}
      >
        {label}
      </p>
    </button>
  );
}

export default ButtonIconVertical;

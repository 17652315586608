import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { z } from 'zod';

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               Document Types                               */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               CategoryKey                                  */
/* -------------------------------------------------------------------------- */

/** Unique ID, lowercase, a-z0-9\- used for deduplication */
export type DTCategoryKey = string;

/* -------------------------------------------------------------------------- */
/*                               CategoryInfo                                 */
/* -------------------------------------------------------------------------- */

/** Collection of keys from all levels to be stored inside a doc to aid search indexing */
export type DTCategoryInfo = DTCategoryKey[];
export const Z_DTCategoryInfo = z.array(z.string());
/* -------------------------------------------------------------------------- */
/*                                  Category                                  */
/* -------------------------------------------------------------------------- */

/** Publicly viewable data for Vendable or Holding - an item that is (or was) sold. */
/** @note We are not currently storing category info in Firebase. */
export interface DTCategory extends NoSqlDoc {
  // Description

  key: DTCategoryKey;

  /** Hierarchy level, from 0-4  */
  level: number;

  /** Parent category, if top level then undefined */
  parent?: string;

  /** Human readable title. English (en) must exist, other languages are optional */
  titles: {
    en: string;
    [lang: string]: string;
  };
}

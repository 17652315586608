/* eslint-disable-next-line */
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Field } from 'formik';
import { InputTypePhoneSettingsShape } from '@rabbit/elements/shared-types';
import PI, { CountryData, PhoneInputProps } from 'react-phone-input-2';

import './style.css';
import { t } from 'i18next';
import { fixPhoneNumber } from '../../../utils/helpers';

export interface InputTypePhoneProps {
  name: string;
  settings: InputTypePhoneSettingsShape;
}

const ReactPhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI;

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  disabled: 'bg-gray-50 border-gray-300 text-gray-400',
  error: 'bg-red-50 border-red-500 text-red-700',
};

function StyledPhoneInput({
  field,
  placeholder,
  setFieldValue,
  form: { touched, errors, meta },
  ...props
}: any) {
  let errorField = false;
  if (errors[field.name] || meta?.errors) errorField = true;
  if (field.name.indexOf('.') > -1) {
    const nameSplit = field.name.split('.');
    if (errors[nameSplit[0]] && errors[nameSplit[0]][nameSplit[1]])
      errorField = true;
  }

  const getCountry = () => {
    return t('CFG_DEFAULT_COUNTRY')
      ? t('CFG_DEFAULT_COUNTRY').toLowerCase()
      : 'us';
  };

  return (
    <ReactPhoneInput
      {...field}
      {...props}
      inputProps={{
        id: props?.id,
        name: field?.name,
      }}
      country={getCountry()}
      countryCodeEditable={false}
      inputClass={classNames(dynamicStyles.base, {
        [`${dynamicStyles.error}`]: errorField,
        [`${dynamicStyles.disabled}`]: props?.disabled,
      })}
      onChange={(value, data: CountryData | {}) => {
        setFieldValue(field.name, value);
        setFieldValue(field.name+"_e164", fixPhoneNumber(value, (data as CountryData)?.dialCode));
      }}
      autoFormat={false}
    />
  );
}

export function InputTypePhone({ name, settings }: InputTypePhoneProps) {
  const { ...rest } = settings;
  const { setFieldValue } = useFormikContext();

  return (
    <Field
      {...rest}
      name={name}
      component={StyledPhoneInput}
      setFieldValue={setFieldValue}
    />
  );
}

export default InputTypePhone;

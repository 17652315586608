import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from '../cf-specs/base';

export const ICF_RefreshIdentityClaims = z
  .object({
    uid: z.string(),
  })
  .merge(ICF_Base);

export const OCF_RefreshIdentityClaims = z.object({
  success: z.boolean(),
});

export const CFC_RefreshIdentityClaims = MakeCloudFunctionSpec(
  'cfcrefreshidentityclaims',
  ICF_RefreshIdentityClaims,
  OCF_RefreshIdentityClaims
);

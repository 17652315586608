import { ReactNode } from 'react';
import {
  ChevronButton,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import bgStart from '../../../assets/images/bg_start.svg';
import bgEnd from '../../../assets/images/bgEnd.png';
import sheltaLogo from '../../../assets/images/shelta-logo.png';
import logoiWarranty from '../../../assets/images/logo/logo-iwarranty-black.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export interface AuthContainerProps {
  children: ReactNode;
  /**
   * Callback function for the back chevron button. If none is set, button is not shown.
   */
  goBack?: null | (() => any);
  /**
   * Optional container title.
   */
  title?: string;
  background?: boolean;
  extraClasses?: string;
  currentStep?: number | null;
  loading?: boolean;
  appInfo?: any; // todo: create interface
}

const dynamicStyles = {
  base: 'w-full md:w-[536px] md:shadow-xl md:m-auto mt-5 relative',
  background: 'bg-top bg-no-repeat bg-cover',
  childrenStyle: 'px-8 py-16 md:px-16',
};

export function AuthContainer({
  children,
  goBack,
  title,
  background = false,
  extraClasses = '',
  currentStep,
  loading,
  appInfo,
}: AuthContainerProps) {
  const { t } = useTranslation();
  const cobrand = t('CFG_COBRAND_LOGO');

  const BackAndTitle = (
    <div className="relative mb-6 flex w-full flex-row">
      {goBack && (
        <ChevronButton
          className="absolute flex h-full cursor-pointer flex-wrap content-center"
          kind="back"
          onClick={goBack}
        />
      )}

      {title && (
        <div className="mx-auto text-center">
          <Heading kind="h2">{title}</Heading>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex grow flex-col lg:content-center">
      <div
        style={{
          backgroundImage:
            !currentStep || currentStep === 1
              ? `${background ? 'url(' + bgStart + ')' : 'none'}`
              : '',
        }}
        className={classNames(dynamicStyles.base, {
          [`${extraClasses}`]: extraClasses,
        })}
      >
        {loading && <LoadingSpinner overlay={true} size="sm" />}
        {currentStep === 9 && <img src={bgEnd} alt={'lastScreenBg'}></img>}
        {currentStep === 1 && (
          <div className="flex px-8 pt-8">
            <img
              src={logoiWarranty}
              alt={'iwarrantyLogo'}
              className="w-[146px]"
            ></img>
            <div className="mx-5 block border-r-2 text-gray-900"></div>

            {cobrand.length > 0 ? (
              <div className="flex items-center">
                <img src={cobrand} className="max-h-[40px] max-w-[200px]"></img>
              </div>
            ) : null}
          </div>
        )}
        <div
          className={classNames(dynamicStyles.childrenStyle, {
            ['md:h-full']: currentStep !== 9,
            ['md:!pt-8']: currentStep === 1,
          })}
        >
          {(goBack || title) && BackAndTitle}
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthContainer;

import { CFC_CheckAPIVersion } from '@rabbit/bizproc/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

/** Global API management on the FE. Don't call this directly, instead use useSageAPI and useOliveAPI */
export function useAPI() {
  const [requestId, setRequestId] = useState<string>(uuidv4());
  const [apiVersion, setApiVersion] = useState<string | null>(null);
  const isReady = !!apiVersion;

  // On hook load, try to get the api version from session storage.
  // All successful CF calls should include that info and store it in session storage.
  // But if for some reason it hasn't been stored yet, ask the API for it
  useEffect(() => {
    const storedApiVersion = sessionStorage.getItem('apiVersion');
    if (
      storedApiVersion &&
      storedApiVersion !== 'null' &&
      storedApiVersion !== 'undefined'
    ) {
      setApiVersion(storedApiVersion);
    } else {
      console.log('Fetching api version');
      // Get the API version
      CFC_CheckAPIVersion.call({ requestId, apiVersion: null })
        .then((version) => {
          if (version.ok) {
            setApiVersion(version.data.apiVersion);
            sessionStorage.setItem('apiVersion', version.data.apiVersion);
            console.log('API version set to', version.data.apiVersion);
          }
        })
        .catch((e) => {
          console.error('Failed to get API version:', e);
        });
    }
  }, []);

  // console.log('requestId', requestId);

  /** Generic API call wrapper */
  async function callAPI(
    fn: (...args: any) => Promise<any>,
    params: { [key: string]: any }
  ) {
    // If there's no api version, throw an error right away
    if (!apiVersion) {
      throw new Error('API version not available on client, aborting...');
    }

    // Check if the current requestID already exists in session storage
    const existingRequestId = sessionStorage.getItem('currentRequestId');

    // use the requestID from hook state
    if (existingRequestId === requestId) {
      console.log('Discarding duplicate request with requestId:', requestId);
      throw new Error('Request already processed');
    }

    sessionStorage.setItem('currentRequestId', requestId);

    // call the function with the provided params + requestID
    try {
      const result = await fn({ requestId, apiVersion, ...params });

      // Regenerate the requestID once the call is complete

      return result.data;
    } catch (err: any) {
      if (err.message.includes('API_VERSION_MISMATCH')) {
        toast.error(
          'App version mismatch detected, please wait while we reload the page...'
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else throw err;
    } finally {
      const newId = uuidv4();
      setRequestId(newId);
    }
  }
  return { requestId, apiVersion, isReady, callAPI };
}
